import React, { useState, useRef,useEffect } from 'react';
import { createproducts } from '../../ReduxToolkit/Slice/Store/Products';
import { getcategory } from '../../ReduxToolkit/Slice/Store/Category';
import { useDispatch ,useSelector} from "react-redux";
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function AddProduct() {

    const dispatch = useDispatch();
    const { categorys } = useSelector((state) => state.category);
    const closeButtonRef = useRef(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [image, setImage] = useState(null);
    const [categoryId, setCategoryId] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData();
        data.append('title', title);
        data.append('description', description);
        data.append('price', price);
        data.append('image', image);
        data.append('categoryId', categoryId);
        dispatch(createproducts(data));
        setTitle("");
        setDescription("");
        setPrice("");
        setImage("");
        setCategoryId("");
        closeButtonRef.current.click();
    };
    useEffect(() => {
        dispatch(getcategory())
    }, [])
    return (
        <>
            <Button children={"Add Product"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#AddProduct" />
            <Model id='AddProduct' title='Create Plan'>
                <form onSubmit={handleSubmit}>
                    <div className="row text-start">
                        <Input label="Title" type="text" bodyclass="col-lg-12" value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter product's title" required />
                        <div className="mb-3 col-lg-12">
                            <label htmlFor="categorySelect" className="form-label">Category</label>
                            <select
                                className="form-select"
                                id="categorySelect"
                                name="categoryId"
                                value={categoryId}
                                onChange={(e) => setCategoryId(e.target.value)}
                            >
                                <option value="">Select a category</option>
                                {categorys.map((category) => (
                                    <option value={category._id} key={category._id}>{category.categoryName}</option>
                                ))}
                            </select>
                        </div>
                        <Input label="Price" type="text" bodyclass="col-lg-12" required value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price" />
                        <Input label="Image" type="file" bodyclass="col-lg-12" required name="image"
                            onChange={(e) => setImage(e.target.files[0])} />
                        <div className="mb-3 col-lg-12">
                            <label htmlFor="descriptionInput" className="form-label">Description</label>
                            <textarea
                                type="text"
                                className="form-control"
                                id="descriptionInput"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter description"
                            />
                        </div>
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Upload"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default AddProduct