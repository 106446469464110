import React, { useEffect, useState } from "react";
import { BgColor } from "../../components/Color/Color";
import { useDispatch, useSelector } from "react-redux";
import {
  getsupport,
  deletesupport,
  updatesupports,
} from "../../ReduxToolkit/Slice/Support/Support";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Icon from "../../components/Button/Icon";
import Button from "../../components/Button/Button";
// import InfiniteScroll from "react-infinite-scroll-component";

function Support() {
  const dispatch = useDispatch();
  const [limit] = useState(20);
  const [pagination, setPagination] = useState(1);
  const { status, supports, error } = useSelector((state) => state.support);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getsupport({ limit, pagination }));
    }
  }, [dispatch, status, limit, pagination]);

  // Delete API
  const handleDelete = (supportid) => {
    const Isconfirmed = window.confirm(
      "Are you sure! you want delete this support?"
    );
    if (Isconfirmed) {
      dispatch(deletesupport(supportid));
    }
  };

  // Update
  const toggleSupportStatus = async (id) => {
    const supportToToggle = supports.find((support) => support._id === id);
    if (!supportToToggle) return;
    const updatedStatus = !supportToToggle.status;
    const data = { status: updatedStatus };
    await dispatch(updatesupports({ id, data }));
  };

  // const fetchMoreData = () => {
  //   setPagination((prevPagination) => prevPagination + 1);
  // };

  useEffect(() => {
    if (pagination > 1) {
      dispatch(getsupport({ limit, pagination }));
    }
  }, [dispatch, pagination, limit]);
  //pagination
  const handlePaginationChange = (newPage) => {
    setPagination(newPage);
    dispatch(getsupport({ limit, pagination: newPage }));
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-between mb-3">
          <h4>Gym supports & Query({supports ? supports.length : ""})</h4>
        </div>
        <div className="table-responsive">
          {/* <InfiniteScroll
            dataLength={supports.length}
            next={fetchMoreData}
            hasMore={supports.length % limit === 0 && supports.length !== 0}
            loader={<h4>Loading...</h4>}
            endMessage={<p style={{ textAlign: "center" }}>No more data</p>}
          > */}
          <table className="table table-hover">
            <thead className={`table-${BgColor}`}>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col">User</th>
                <th scope="col">Description</th>
                <th scope="col">Date</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" && (
                <tr className="my-2">
                  <td colSpan={7}>
                    <Skeleton count={10} height={60} />
                  </td>
                </tr>
              )}
              {status === "failed" && (
                <tr>
                  <td colSpan={7}>
                    <p className="text-center fw-bold">{error}</p>
                  </td>
                </tr>
              )}
              {status === "succeeded" &&
                supports &&
                supports.map((support, index) => {
                  const data = new Date(support.createdAt);
                  const formatdate = data.toLocaleDateString("default", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  });
                  return (
                    <tr key={support._id}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        {support.userData
                          ? support.userData.name.charAt(0).toUpperCase() +
                            support.userData.name.slice(1)
                          : null}
                        <br />
                        {support.userData ? support.userData.phone : null}
                      </td>
                      <td>{support.description}</td>
                      <td>{formatdate}</td>
                      <td
                        className="cursor"
                        onClick={() => toggleSupportStatus(support._id)}
                      >
                        {support.status ? (
                          <i className="bg-success px-2 rounded-pill text-white">
                            Completed
                          </i>
                        ) : (
                          <i className="bg-warning px-2 rounded-pill">
                            Pending
                          </i>
                        )}
                      </td>
                      <td className="cursor">
                        <span onClick={() => handleDelete(support._id)}>
                          <Icon
                            children={"trash"}
                            textColor="danger"
                            size={"5"}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {/* </InfiniteScroll> */}
        </div>
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <p>{`Showing ${pagination} to ${supports.length} of ${supports.length} Projects`}</p>
          </div>
          <div className="col-lg-4 d-flex justify-content-end">
            <div className="pagination">
              {/* Previous button */}
              <Button
                children={"Previous"}
                onClick={() =>
                  handlePaginationChange(Math.max(1, pagination - 1))
                }
                disabled={pagination === 1}
                className="mx-1"
              />
              {/* Next button */}
              <Button
                children={"Next"}
                onClick={() => handlePaginationChange(pagination + 1)}
                disabled={supports.length < limit}
                className="mx-1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
