import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFlow } from "./FlowContext";

function PrivateRoute({ Component }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasAccess } = useFlow();

  useEffect(() => {
    const tokenData = localStorage.getItem("token");
    const publicRoutes = [
      "/login",
      "/send-otp",
      "/verify-otp",
      "/forget-Password",
    ];

    if (!tokenData && !publicRoutes.includes(location.pathname)) {
      navigate("/login");
    } 
    // else if (!hasAccess && location.pathname === "/forget-Password") {
    //   navigate("/login"); 
    // }
  }, [navigate, location.pathname, hasAccess]);

  return <Component />;
}

export default PrivateRoute;
