import React, { useState, useEffect,useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getcategory, deletecategorys, updatecategorys } from '../../ReduxToolkit/Slice/Store/Category';
import Input from '../../components/Input/Input';
import Model from '../../components/Model/Model';
import Button from '../../components/Button/Button';
import CreateCategory from './CreateCategory';
import PageTitle from '../../components/Title/PageTitle';
import Table from '../../components/Table/Table';

function Category() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const { status, categorys,error } = useSelector((state) => state.category);
    const [categoryName, setCategoryName] = useState("");
    const [categoryImg, setCategoryImg] = useState(null);
    useEffect(() => {
        if (status === "idle") {
            dispatch(getcategory());
        }
    }, [dispatch, status]);
    // single data
    const [selectedId, setSelectedId] = useState(null);
    const handlid = (id) => {
        const selectedcategorys = categorys.find(category => category._id === id);
        setCategoryName(selectedcategorys.categoryName);
        setCategoryImg(selectedcategorys.categoryImg);
        setSelectedId(id);
    };
    // update 
    const updatecategory = (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('categoryName', categoryName)
        data.append('categoryImg', categoryImg)
        dispatch(updatecategorys({ selectedId, data }))
        setCategoryName("");
        setCategoryImg(null);
        closeButtonRef.current.click();
    }
    // delete api
    const handleDelete = (categoryid) => {
        const Isconfirmed = window.confirm("Are you sure! you want delete this category ?")
        if (Isconfirmed) {
            dispatch(deletecategorys(categoryid))
        };
    }
    // table
    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    const columns = [
        { label: "Category Name", accessor: "categoryName" },
        { label: "Date", accessor: "createdAt", formatter: formatDate },
    ];
    return (
        <>
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <PageTitle children={`Product Categorys (${categorys.length ? categorys.length : ""})`}/>
                    <CreateCategory/>
                </div>
                <Table
                    columns={columns}
                    data={categorys || []}
                    status={status}
                    onDelete={handleDelete}
                    onEdit={handlid}
                    error={error}
                    Image={"image"}
                    databstarget="#exampleModal"
                    model="modal"
                />
            </div>

            {/* Modal  */}
            <Model id='exampleModal' title='Create Category'>
                <form onSubmit={updatecategory}>
                    <div className="row text-start">
                        <Input label="Name" type="text" bodyclass="col-lg-12" value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Enter category Name" required />

                        <Input label="Image" type="file" bodyclass="col-lg-12"  name="image"
                            onChange={(e) => setCategoryImg(e.target.files[0])} />
                      
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Update"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    );
}

export default Category;
