import React, { useState, useEffect, useRef } from "react";
import { BgColor, TextColor } from "../../components/Color/Color";
import { useDispatch, useSelector } from "react-redux";
import {
  gettrainer,
  deletetrainers,
  updatetrainers,
} from "../../ReduxToolkit/Slice/Trainer/Trainer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AddTrainer from "./AddTrainer";
import PageTitle from "../../components/Title/PageTitle";
import Model from "../../components/Model/Model";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Icon from "../../components/Button/Icon";
import { dummyImage } from "../../components/Img/Img";

function Trainer() {
  const dispatch = useDispatch();
  const closeButtonRef = useRef(null);
  const { initialFetch, trainers, error } = useSelector(
    (state) => state.trainer
  );
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [experience, setExperience] = useState("");
  const [address, setAddress] = useState("");
  const [salary, setSalary] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (initialFetch === "idle") {
      dispatch(gettrainer());
    }
  }, [dispatch, initialFetch]);

  const [selectedId, setSelectedId] = useState(null);

  const handlid = (id) => {
    const selectedtrainers = trainers.find((trainer) => trainer._id === id);
    setName(selectedtrainers.name);
    setEmail(selectedtrainers.email);
    setPhone(selectedtrainers.phone);
    setAge(selectedtrainers.age);
    setAddress(selectedtrainers.address);
    setSalary(selectedtrainers.salary);
    setExperience(selectedtrainers.experience);
    setSelectedId(id);
  };

  // Optimized Form Submission Function
  const updatetrainer = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formFields = {
      name,
      email,
      phone,
      age,
      address,
      salary,
      image,
      experience,
    };
    for (let field in formFields) {
      formData.append(field, formFields[field]);
    }
    dispatch(updatetrainers({ selectedId, formData }));
    // Reset form fields
    Object.keys(formFields).forEach((field) => (formFields[field] = ""));
    closeButtonRef.current.click();
  };
  //   delete function
  const handleDelete = (trainerid) => {
    const Isconfirmed = window.confirm(
      "Are you sure! you want delete this trainer ?"
    );
    if (Isconfirmed) {
      dispatch(deletetrainers(trainerid));
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between mb-3">
          <PageTitle
            children={`Gym Trainers (${
              trainers.length ? trainers.length : ""
            })`}
          />
          <AddTrainer />
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead className={`table-${BgColor}`}>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col">Name</th>
                <th scope="col">Number</th>
                <th scope="col">Email</th>
                <th scope="col">Age</th>
                <th scope="col">Experience</th>
                <th scope="col">Salary</th>
                <th scope="col">Address</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {initialFetch === "loading" && (
                <tr className="my-2">
                  <td colSpan={9}>
                    <Skeleton count={10} height={60} />
                  </td>
                </tr>
              )}
              {initialFetch === "failed" && (
                <tr>
                  <td colSpan={9}>
                    <p className="text-center fw-bold text-danger">{error}</p>
                  </td>
                </tr>
              )}
              {initialFetch === "succeeded" &&
                trainers &&
                trainers.map((trainer, index) => (
                  <tr key={trainer._id}>
                    <th scope="row">
                      <div className="position-relative">
                        <span
                          className={`position-absolute bottom-0 start-0  rounded-circle text-${TextColor} bg-${BgColor} text-center`}
                          style={{
                            fontSize: "10px",
                            height: "17px",
                            width: "17px",
                          }}
                        >
                          {index + 1}
                        </span>
                        {trainer.image ? (
                          <img
                            src={trainer.image}
                            height={"50px"}
                            width={"50px"}
                            className="rounded-circle img-fit"
                            alt="img"
                          />
                        ) : (
                          <img
                            src={dummyImage}
                            height={"50px"}
                            width={"50px"}
                            className="rounded-circle img-fit"
                            alt="img"
                          />
                        )}
                      </div>
                    </th>
                    <td>
                      {trainer.name.charAt(0).toUpperCase() +
                        trainer.name.slice(1)}
                    </td>
                    <td>{trainer.phone}</td>
                    <td>{trainer.email}</td>
                    <td>{trainer.age} Years</td>
                    <td>{trainer.experience}</td>
                    <td>{trainer.salary}</td>
                    <td>{trainer.address}</td>
                    <td className="cursor">
                      <span onClick={() => handlid(trainer._id)}>
                        <Icon
                          children={"pencil-square"}
                          size={"5"}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        />
                      </span>
                      <span onClick={() => handleDelete(trainer._id)}>
                        {" "}
                        <Icon
                          children={"trash"}
                          textColor="danger"
                          size={"5"}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Model id="exampleModal" title="Update Trainer">
        <form onSubmit={updatetrainer}>
          <div className="row text-start">
            <Input
              label="Name"
              type="text"
              bodyclass="col-lg-12"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Trainer Name"
            />

            <Input
              label="Email"
              type="email"
              bodyclass="col-lg-12"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter Email"
            />

            <Input
              label="Phone"
              type="text"
              bodyclass="col-lg-12"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter Phone Number"
            />

            <Input
              label="Age"
              type="text"
              bodyclass="col-lg-12"
              required
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Enter Age"
            />

            <Input
              label="Experience"
              type="text"
              bodyclass="col-lg-12"
              required
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              placeholder="Enter Experience"
            />
            <Input
              label="Address"
              type="text"
              bodyclass="col-lg-12"
              required
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter Address"
            />
            <Input
              label="Salary"
              type="text"
              bodyclass="col-lg-12"
              required
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              placeholder="Enter Salary"
            />
            <Input
              label="Image"
              type="file"
              bodyclass="col-lg-12"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <div className={`modal-footer`}>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              >
                Close
              </button>
              <Button type="submit" children={"Update"}></Button>
            </div>
          </div>
        </form>
      </Model>
    </>
  );
}

export default Trainer;
