import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { FlowProvider } from './FlowContext';
import Layout from './Layout/Layout';
import Home from './Page/Home/Home';
import Login from './Page/Login/Login';
import Member from './Page/Membar/Member';
import Plan from './Page/Plan/Plan';
import Profile from './Page/Profile/Profile';
import Notification from './Page/Notification/Notification';
import MemberProfile from './Page/Membar/MemperProfile';
import Products from './Page/Store/Products';
import Category from './Page/Store/Category';
import Support from './Page/Support/Support';
import Trainer from './Page/Trainer/Trainer';
import Feedback from './Page/Feedback/Feedback';
import Order from './Page/Store/Order';
import Orderdetails from './Page/Store/OrderDetails';
import Banner from './Page/Banner/Banner';
import Attendance from './Page/Membar/Attendace';
import Competition from './Page/Competition/Competition';
import PaymentHistory from './Page/Plan/PaymentHistory';
import User from './Page/Membar/User';
import SendEmail from './Page/Login/SendEmail';
import PrivateRoute from './PrivateRoute';
import VerifyOtpPage from './Page/Login/VerifyOtpPage';
import ForgetPasswordPage from './Page/Login/ForgetPasswrodPage';

function App() {
  return (
    <FlowProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="forget-Password" element={<ForgetPasswordPage />} />
            <Route path="send-otp" element={<SendEmail />} />
            <Route path="verify-otp" element={<VerifyOtpPage />} />
            <Route path="profile" element={<PrivateRoute Component={Profile} />} />
            <Route path="members" element={<PrivateRoute Component={Member} />} />
            <Route path="memberProfile/:id" element={<PrivateRoute Component={MemberProfile} />} />
            <Route path="plan" element={<PrivateRoute Component={Plan} />} />
            <Route path="notification" element={<PrivateRoute Component={Notification} />} />
            <Route path="products" element={<PrivateRoute Component={Products} />} />
            <Route path="category" element={<PrivateRoute Component={Category} />} />
            <Route path="support" element={<PrivateRoute Component={Support} />} />
            <Route path="order" element={<PrivateRoute Component={Order} />} />
            <Route path="orderdetails/:id" element={<PrivateRoute Component={Orderdetails} />} />
            <Route path="trainer" element={<PrivateRoute Component={Trainer} />} />
            <Route path="feedback" element={<PrivateRoute Component={Feedback} />} />
            <Route path="banner" element={<PrivateRoute Component={Banner} />} />
            <Route path="attendance/:id" element={<PrivateRoute Component={Attendance} />} />
            <Route path="competition" element={<PrivateRoute Component={Competition} />} />
            <Route path="payment-history" element={<PrivateRoute Component={PaymentHistory} />} />
            <Route path="user" element={<PrivateRoute Component={User} />} />
          </Route>
        </Routes>
      </Router>
    </FlowProvider>
  );
}

export default App;
