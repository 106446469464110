import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SendOtpApi} from "../../../Api_url";
import { toast } from "react-toastify";
// send otp
export const SendOtp = createAsyncThunk(
  "SendOtp/forget",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(SendOtpApi, data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const forgetSlice = createSlice({
  name: "forget",
  initialState: {
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create forget
      .addCase(SendOtp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SendOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          toast.success(state.message);
        }
      })
      .addCase(SendOtp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
  },
});

export default forgetSlice.reducer;
