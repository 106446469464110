import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { categoryapi, createcategoryapi, updatecategoryapi, deletecategoryapi } from "../../../Api_url";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};
// create category
export const createcategory = createAsyncThunk('createcategory/category', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createcategoryapi, data, { headers })
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get category
export const getcategory = createAsyncThunk('getcategory/category', async () => {
    try {
        const response = await axios.get(`${categoryapi}/${_id}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// update
export const updatecategorys = createAsyncThunk('updatecategorys/category', async ({ selectedId, data }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updatecategoryapi}/${selectedId}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// delete category
export const deletecategorys = createAsyncThunk('deletecategorys/category', async (categoryid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deletecategoryapi}/${categoryid}`, { headers });
        return response.data._id;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categorys: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create category
            .addCase(createcategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createcategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(createcategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update category
            .addCase(updatecategorys.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatecategorys.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(updatecategorys.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })

            //getcategorys
            .addCase(getcategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getcategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categorys = action.payload;
            })
            .addCase(getcategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete categorys
            .addCase(deletecategorys.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletecategorys.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categorys = state.categorys.filter(categorys => categorys.categoryid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle'
            })
            .addCase(deletecategorys.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default categorySlice.reducer;
