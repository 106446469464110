import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./Slice/Login/LoginSlice";
import Member from "./Slice/Member/Member";
import Plan from "./Slice/Plan/Plan";
import Profile from "./Slice/Profile/Profile";
import Notification from "./Slice/Notification/Notification";
import Products from "./Slice/Store/Products";
import Category from "./Slice/Store/Category";
import Support from "./Slice/Support/Support";
import Order from "./Slice/Store/Order";
import Banner from "./Slice/Banner/Banner";
import Trainer from "./Slice/Trainer/Trainer";
import ColorTheme from "./Slice/Profile/ColorTheme";
import Competition from "./Slice/Competition/Competition";
import Forget from "./Slice/Login/ForgetSlice";
import Verify from "./Slice/Login/VerifySlice";
import Password from './Slice/Login/password'

const rootReducer = combineReducers({
  auth: authSlice,
  member: Member,
  plan: Plan,
  profile: Profile,
  notification: Notification,
  products: Products,
  category: Category,
  support: Support,
  order: Order,
  banner: Banner,
  trainer: Trainer,
  colorTheme: ColorTheme,
  competition: Competition,
  forget: Forget,
  verify: Verify,
  password: Password,
});

export default rootReducer;
