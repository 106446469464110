import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { totalcount } from "../../ReduxToolkit/Slice/Profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import { BgColor, TextColor } from "../../components/Color/Color";
import Icon from "../../components/Button/Icon";
import PageTitle from "../../components/Title/PageTitle";

function Home() {
  const dispatch = useDispatch();
  const { status, totalcounts, error } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(totalcount());
  }, [dispatch]);

  const cards = [
    {
      id: 1,
      title: "Members",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.UserCount
        ),
      icon: <Icon children={"people"} size={"2"} />,
      link: "members",
    },
    {
      id: 2,
      title: "Plan",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.UserPlanCount
        ),
      icon: <Icon children={"ui-checks-grid"} size={"2"} />,
      link: "plan",
    },
    {
      id: 3,
      title: "Trainer",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.TrainerCount
        ),
      icon: <Icon children={"person"} size={"2"} />,
      link: "trainer",
    },
    {
      id: 4,
      title: "Store",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.ProductCount
        ),
      icon: <Icon children={"shop-window"} size={"2"} />,
      link: "products",
    },
    {
      id: 5,
      title: "Category",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.CategoryCount
        ),
      icon: <Icon children={"ui-radios-grid"} size={"2"} />,
      link: "category",
    },
    {
      id: 6,
      title: "Order",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.OrderCount
        ),
      icon: <Icon children={"cart"} size={"2"} />,
      link: "order",
    },
    {
      id: 7,
      title: "Revenue",
      number:
        status === "loading" ? (
          <span className={`spinner-border text-${BgColor}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </span>
        ) : (
          totalcounts.TotalRevenue
        ),
      icon: <Icon children={"currency-rupee"} size={"2"} />,
      link: "payment-history",
    },
  ];

  if (status === "failed") {
    return <div>Error: {error} Something Went Wrong </div>;
  }

  return (
    <>
      <div className="row">
        <PageTitle children={"Dashboard"} />
        {cards &&
          cards.map((card) => (
            <div className="col-lg-4 col-6 my-2" key={card.id}>
              <Link to={card.link}>
                <div
                  className={`d-flex justify-content-between bg-${TextColor} text-dark p-3 rounded shadow-sm border-start border-3 border-${BgColor}`}
                >
                  <div className="content-center">
                    <div>
                      <i>{card.icon}</i>
                      <div>{card.title}</div>
                    </div>
                  </div>
                  <div className="content-center">
                    <div className="text-end">
                      <div className={`fs-1 text-${BgColor}`}>
                        {card.number}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </>
  );
}

export default Home;
