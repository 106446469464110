import React, { useEffect, useState, useRef } from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from "react-redux";
import { updateprofiles, getprofile, deleteprofiles } from '../../ReduxToolkit/Slice/Profile/Profile';
import { useNavigate } from 'react-router-dom';
import CustomFileUpload from './CustomFileUpload';
import ColorTheme from './ColorTheme';
import Button from '../../components/Button/Button';
import Icon from '../../components/Button/Icon';
import Input from '../../components/Input/Input';
import Model from '../../components/Model/Model';
export const id = localStorage.getItem("_id")

function Profile() {
    const closeButtonRef = useRef(null);
    const history = useNavigate()
    const logout = () => {
        history("/login")
        localStorage.removeItem('token')
        localStorage.removeItem('_id')

    }
    // support call
    const handleSupportCall = () => {
        const confirmed = window.confirm("Are you sure you want to call support?");
        if (confirmed) {
            window.open("tel:8700282172");
        }
    };
    // Updateplan call
    const handleUpdatePlan = () => {
        const confirmed = window.confirm("Are you sure you want to Update Plan?");
        if (confirmed) {
            window.open("tel:8700282172");
        }
    };
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { status, profiles, error } = useSelector((state) => state.profile);
    // get profiles
    const planUpdateDate = profiles.gym && profiles.gym.planUpdatedOn ? new Date(profiles.gym.planUpdatedOn) : null;
    const updateDate = planUpdateDate ? planUpdateDate.toLocaleDateString("default", {
        day: "2-digit",
        month: "long",
        year: "numeric"
    }) : '';

    const expiryDate = profiles.gym && profiles.gym.ExpiryDate ? new Date(profiles.gym.ExpiryDate) : null;
    const expiryDates = expiryDate ? expiryDate.toLocaleDateString("default", {
        day: "2-digit",
        month: "long",
        year: "numeric"
    }) : '';



    // update profile
    const [gymName, setGymName] = useState();
    const [ownerName, setOwnerName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [memberInGym, setMemberInGym] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    // update image
    useEffect(() => {
        if (status === "idle") {
            dispatch(getprofile());
        }
        if (profiles.gym) {
            setGymName(profiles.gym.gymName);
            setOwnerName(profiles.gym.ownerName);
            setPhone(profiles.gym.phone);
            setEmail(profiles.gym.email);
            setAddress(profiles.gym.address);
            setCity(profiles.gym.city);
            setState(profiles.gym.state);
            setPincode(profiles.gym.pincode);
            setMemberInGym(profiles.gym.memberInGym);
            setPaymentMode(profiles.gym.paymentMode);
        }

    }, [dispatch, status, profiles]);
    // update profile
    const updateprofile = (e) => {
        e.preventDefault()
        try {
            const formData = {
                gymName,
                ownerName,
                phone,
                email,
                address,
                city,
                state,
                pincode,
                memberInGym,
                paymentMode
            }
            dispatch(updateprofiles(formData));
            closeButtonRef.current.click();
        } catch (error) {
            console.log("Error in Submission", error);
        }
    }

    const deleteprofile = () => {
        try {
            const Isconfirmed = window.confirm('Are you sure to Delete your Profile?');
            if (Isconfirmed) {
                dispatch(deleteprofiles(id))
                    .then(() => {
                        navigate('/login')
                        localStorage.removeItem('token');
                        localStorage.removeItem('_id')
                    })
            }
        } catch (error) {
            alert("Profile Deletion Failed")
        }
    }

    return (
        <>
            <div className="row flex-row-reverse">
                {status === "loading" && (
                    <>
                        <div className='col-lg-4'>
                            <div className='p-3'>
                                <Skeleton height={400} width={"100"} />
                            </div>
                        </div>
                        <div className='col-lg-8'>
                            <div className='p-3'>
                                <Skeleton height={400} width={"100"} />
                            </div>
                        </div>
                    </>
                )}
                {status === "failed" && (
                    <div className='text-center'>{error}</div>
                )}
                {
                    status === "succeeded" && (
                        <>
                            <div className="col-lg-4 my-2">
                                <div className='shadow-sm text-center bg-white rounded p-3'>
                                    <CustomFileUpload image={profiles.gym.image} />
                                    {profiles.gym.plan ? <h3>{profiles.gym.plan.name.charAt(0).toUpperCase() + profiles.gym.plan.name.slice(1)}/{profiles.gym.plan.price}</h3> : <div>
                                        <h5 className='text-danger'>No Active Plan!</h5>
                                    </div>}
                                    <Button children={"Update Plan"} type='buttton' onClick={handleUpdatePlan}/>
                                    {profiles.gym.plan ? <h6 className='my-2 text-secondary'> <i>Expired on {expiryDates}</i> </h6> : ""}

                                    <table className="table text-start">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Edit Profile</th>
                                                <td>
                                                    <Icon children="pencil-square" size={"6"} className="cursor" data-bs-toggle="modal" data-bs-target="#exampleModal" />
                                                </td>
                                            </tr>
                                            <ColorTheme />
                                            {/* <tr>
                                                <th scope="row">App Theme</th>
                                                <td>
                                                    <Icon children="google-play" size={"6"} className="cursor" />
                                                </td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">Support</th>
                                                <td onClick={handleSupportCall}>
                                                    <Icon children="headset" size={"6"} className="cursor" />
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                    <Button children="Logout" type='button' bgColor='light' textColor='danger' onClick={logout} />
                                </div>
                            </div>
                            <div className="col-lg-8 my-2">
                                <div className='shadow-sm bg-white rounded p-3'>
                                    <h4 className=''>Gym Deatils</h4>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name of GYM</th>
                                                <td>{profiles.gym.gymName.charAt(0).toUpperCase() + profiles.gym.gymName.slice(1)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Name</th>
                                                <td>{profiles.gym.ownerName.charAt(0).toUpperCase() + profiles.gym.ownerName.slice(1)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone</th>
                                                <td>{profiles.gym.phone}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email</th>
                                                <td>{profiles.gym.email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Member In Gym</th>
                                                <td>{profiles.TotalUserCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Male</th>
                                                <td>{profiles.MaleUserCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Female</th>
                                                <td>{profiles.FemaleUserCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Plan Date</th>
                                                <td>{updateDate}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Payment Mode</th>
                                                <td>{profiles.gym.paymentMode}</td>
                                            </tr> */}
                                            {/* <tr>
                                                <th scope="row">lat & long</th>
                                                <td>{profiles.gym.lat},{profiles.gym.long}</td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">Address</th>
                                                <td>{profiles.gym.address},{profiles.gym.state},{profiles.gym.city},{profiles.gym.pincode}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Button children="Delete Account" bgColor='danger' type='button' onClick={deleteprofile} />
                            </div>
                        </>
                    )
                }
            </div>
            {/* Modal  */}
            <Model id='exampleModal' title='Update Profile'>
                <form onSubmit={updateprofile}>
                    <div className="row text-start">
                        <Input label="Gym Name" type="text" bodyclass="col-lg-6" placeholder="Enter Gym Name" required value={gymName}
                            onChange={(e) => setGymName(e.target.value)} />
                        <Input label="Owner Name" bodyclass="col-lg-6" type="text" value={ownerName}
                            onChange={(e) => setOwnerName(e.target.value)}
                            placeholder="Enter Owner" required />


                        <div className="mb-3 col-lg-6">
                            <label htmlFor="emailInput" className="form-label">Owner Name</label>

                            <select name="" id="" className='form-control'
                                value={memberInGym}
                                onChange={(e) => setMemberInGym(e.target.value)}
                            >
                                <option value="less than 100">less than 100</option>
                                <option value="More than 100">More than 100</option>
                            </select>
                        </div>
                        <Input label="Phone" bodyclass="col-lg-6" type="tel" name="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)} required />
                        <Input label="Email" bodyclass="col-lg-6" type="email" name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} required />


                        <Input label="Address" bodyclass="col-lg-6" type="text" name="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)} required />

                        <Input label="State" bodyclass="col-lg-6" type="text" name="state"
                            value={state}
                            onChange={(e) => setState(e.target.value)} required />

                        <Input label="City" bodyclass="col-lg-6" type="text" name="City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)} required />
                        <Input label="Pincode" bodyclass="col-lg-6" type="text" name="pincode"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)} required />
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button children={"Update"} type='submit'></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default Profile