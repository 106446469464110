import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallcolortheme, selecttheme, selectedtheme } from '../../ReduxToolkit/Slice/Profile/ColorTheme';
import { BgColor, TextColor } from '../../components/Color/Color';

function ColorTheme() {
    const dispatch = useDispatch();
    const { allthemes, selectedthemecolor } = useSelector((state) => state.colorTheme);
    useEffect(() => {
        dispatch(getallcolortheme());
        dispatch(selectedtheme());
    }, [dispatch]);

    const [selectedTheme, setSelectedTheme] = useState('');
    // Safe access to theme colors
    const bgColor = selectedthemecolor.length > 0 ? selectedthemecolor[0]?.themeData?.bgColor || 'dark' : 'dark';
    const textColor = selectedthemecolor.length > 0 ? selectedthemecolor[0]?.themeData?.textColor || 'white' : 'white';
    // Handle the theme update
    const updateTheme = () => {
        if (selectedTheme) {
            const data = { themeData: selectedTheme };
            dispatch(selecttheme(data));
            setSelectedTheme("");
        }
    };



    // Persist theme colors to localStorage
    useEffect(() => {
        localStorage.setItem("bgColor", bgColor);
        localStorage.setItem("TextColor", textColor);
    }, [bgColor, textColor]);

    return (
        <>
            <div className="modal fade" id="exampleModal1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog rounded">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Change Theme</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <select className="form-control" value={selectedTheme} onChange={(e) => setSelectedTheme(e.target.value)}>
                                <option value="" className='d-none'>Select Theme</option>
                                {allthemes.map((color) => (
                                   <option className={`text-${color.bgColor} fw-bold fs-5`} value={color._id} key={color._id}>{color.bgColor.charAt(0).toUpperCase() + color.bgColor.slice(1)}</option>

                                ))}
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-${BgColor} text-${TextColor}`} onClick={updateTheme} data-bs-dismiss="modal">Change Theme</button>
                        </div>
                    </div>
                </div>
            </div>
            <tr>
                <th scope="row">Theme</th>
                <td>
                    <i className={`bi bi-palette cursor text-${BgColor}`} data-bs-toggle="modal" data-bs-target="#exampleModal1"></i>
                </td>
            </tr>
        </>
    );
}

export default ColorTheme;
