import React from "react";
import { BgColor, TextColor } from '../../components/Color/Color';

function Icon({
    children,
    textColor = BgColor,
    size="",
    className = "",
    ...props
}) {
    return (
        <i
            className={`text-${textColor} ${className} bi bi-${children} fs-${size}`}
            {...props}
        >
        </i>
    );
}

export default Icon;
