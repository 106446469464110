import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { createcompetitionapi, competitionapi, competitionupdateapi, addprizeapi, competitiondeleteapi, deleteprizeapi, updatecompetitionsstatusapi, createwinnerapi, getwinnerapi,deletewinnerapi } from "../../../Api_url";
export const token = localStorage.getItem("token")

const headers = {
    Authorization: `Bearer ${token}`,
};
// create competition
export const createcompetition = createAsyncThunk('createcompetition/competition', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createcompetitionapi, data, { headers })
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get competition
export const getwinner = createAsyncThunk('getwinner/competition', async (competetionID) => {
    try {
        const response = await axios.get(`${getwinnerapi}/${competetionID}`, { headers });
        console.log(response)
        return response.data; 
    } catch (error) {
        throw new Error(error.message);
    }
});
// get competition
export const getcompetition = createAsyncThunk('getcompetition/competition', async ({ limit, pagination, search, Gender }) => {
    try {
        const response = await axios.get(`${competitionapi}?limit=${limit}&page=${pagination}&searchQuary=${search}&Gender=${Gender}`, { headers });
        return response.data; // Assuming your API response contains a 'competitions' property
    } catch (error) {
        throw new Error(error.message);
    }
});

// get single competition
export const getSinglecompetition = createAsyncThunk('getSinglecompetition/competition', async (id) => {
    try {
        const response = await axios.get(`${competitionapi}/${id}`, { headers });
        return response.data; // Assuming your API response contains a 'competitions' property
    } catch (error) {
        throw new Error(error.message);
    }
});
// update
export const updatecompetitions = createAsyncThunk('updatecompetitions/competition', async ({ selectedId, data }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`${competitionupdateapi}/${selectedId}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// update status
export const updatecompetitionsstatus = createAsyncThunk('updatecompetitionsstatus/competition', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`${updatecompetitionsstatusapi}/${id}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// add prize
export const addprize = createAsyncThunk('addprize/competition', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`${addprizeapi}/${id}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// delete competition
export const deletecompetitions = createAsyncThunk('deletecompetitions/competition', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${competitiondeleteapi}/${id}`, { headers });
        return response.data._id; // Assuming your API response contains a 'competitionid' property
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// delete prize
export const deleteprize = createAsyncThunk('deleteprize/competition', async ({ comId, prizeId }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deleteprizeapi}/${comId}/${prizeId}`, { headers });
        return response.data._id; // Assuming your API response contains a 'competitionid' property
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
// delete prize
export const deletewinner = createAsyncThunk('deleteprize/deletewinner', async ({winnerId}, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deletewinnerapi}/${winnerId}`, { headers });
        return response.data._id; // Assuming your API response contains a 'competitionid' property
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// create winner
export const createWinner = createAsyncThunk('createWinner/competition', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${createwinnerapi}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});


const competitionSlice = createSlice({
    name: 'competition',
    initialState: {
        competitions: [],
        Singlecompetition: {},
        Getattendance: [],
        winners: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create competition
            .addCase(createcompetition.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createcompetition.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(createcompetition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update competition
            .addCase(updatecompetitions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatecompetitions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(updatecompetitions.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }

            })
            //update status
            .addCase(updatecompetitionsstatus.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatecompetitionsstatus.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                state.status = 'idle';

            })
            .addCase(updatecompetitionsstatus.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }

            })
            // add prize
            .addCase(addprize.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addprize.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';
            })
            .addCase(addprize.rejected, (state, action) => {
                state.status = 'failed';
                state.message = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.message)
                }
            })
            // createWinner
            .addCase(createWinner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createWinner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';
            })
            .addCase(createWinner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)
                }
            })


            //getcompetitions
            .addCase(getcompetition.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getcompetition.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.competitions = action.payload;
            })
            .addCase(getcompetition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //getwinner
            .addCase(getwinner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getwinner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.winners = action.payload;
            })
            .addCase(getwinner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //getSinglecompetition
            .addCase(getSinglecompetition.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getSinglecompetition.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.Singlecompetition = action.payload;
            })
            .addCase(getSinglecompetition.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete competitions
            .addCase(deletecompetitions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletecompetitions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.competitions = state.competitions.filter(competitions => competitions.competitionid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Delete competition Successfully!!")
                }
                state.status = 'idle'
            })
            .addCase(deletecompetitions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //delete prize
            .addCase(deleteprize.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteprize.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.competitions = state.competitions.filter(competitions => competitions.competitionid !== action.payload);
                state.status = 'idle'
            })
            .addCase(deleteprize.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deletewinner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletewinner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.winners = state.winners.filter(winners => winners.winnerId !== action.payload);
                state.status = 'idle'
            })
            .addCase(deletewinner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default competitionSlice.reducer;
