import React, { useEffect } from 'react'
import { BgColor, TextColor } from '../Color/Color'
import { useDispatch, useSelector } from "react-redux";
import { getnotificationunread, VerifyNotification } from '../../ReduxToolkit/Slice/Notification/Notification';
import Skeleton from 'react-loading-skeleton'
import Icon from '../Button/Icon';


function NotificationBell() {
    const dispatch = useDispatch();
    const { status, notifications } = useSelector((state) => state.notification);
    console.log(notifications)
    useEffect(() => {
        if (status === "idle") {
            dispatch(getnotificationunread());
        }
    }, [dispatch, status]);
    // const reversedData = notifications.notificationData && Array.isArray(notifications.notificationData)
    //     ? notifications.notificationData.slice().reverse()
    //     : [];

    // update status
    const handleReadNotification = async (id) => {
        const notificationToToggle = notifications.notificationData.find(notification => notification._id === id);
        console.log(id)
        if (!notificationToToggle) return;
        const updatedStatus = !notificationToToggle.status;
        const data = { status: updatedStatus };
        await dispatch(VerifyNotification({ id, data }));
    };
    return (
        <>
            <div className="mx-3 mx-md-4 cursor my-1">
                <div className="bi bi-bell position-relative fs-5" data-bs-toggle="offcanvas" href="#offcanvasExample2" role="button" aria-controls="offcanvasExample">
                    <span className={`position-absolute top-0 start-100 translate-middle badge rounded-circle text-${BgColor} bg-${TextColor}`} style={{ fontSize: "11px" }}>
                        {notifications.notificationData ? notifications.notificationData.length : ""}
                    </span>
                </div>
            </div>
            {/* offcanvas notification */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample2" aria-labelledby="offcanvasExampleLabe">
                <div className="offcanvas-header border-bottom">
                    <h4 className="color">Notifications ({notifications.totalNotification})</h4>
                    <i className="btn-close text-light cursor" data-bs-dismiss="offcanvas" aria-label="Close"></i>
                </div>

                <div className="offcanvas-body px-0">
                    {status === "faild" && (
                        <div className="text-center">Notification Not Found!</div>
                    )}
                    {status === "loading" && (

                        <>
                            {Array.from({ length: 10 }).map((_, index) => (
                                <div className="border rounded p-1 m-2"
                                    key={index}
                                >
                                    <div className="d-flex">
                                    <span className='mx-2'>
                                    <Skeleton circle={true} height={30} width={30} />
                                    </span>
                                        <div>
                                            <Skeleton width={300} height={10} />
                                            <Skeleton width={300} height={30} />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <Skeleton width={100} height={10} />
                                    </div>
                                </div>
                            ))}
                        </>


                    )}
                    {status === "succeeded" && (
                        notifications.notificationData.map((item) => {
                            const dateTime = new Date(item.createdAt);
                            const year = dateTime.getFullYear();
                            const month = ("0" + (dateTime.getMonth() + 1)).slice(-2); // Months are 0-based
                            const day = ("0" + dateTime.getDate()).slice(-2);
                            const hours = ("0" + dateTime.getHours()).slice(-2);
                            const minutes = ("0" + dateTime.getMinutes()).slice(-2);
                            const seconds = ("0" + dateTime.getSeconds()).slice(-2);
                            // Get the day of the week
                            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            const dayOfWeekIndex = dateTime.getDay();
                            const dayOfWeek = daysOfWeek[dayOfWeekIndex];
                            const formattedDateTime = `${year}-${month}-${day} (${hours}:${minutes}:${seconds}) ${dayOfWeek}`;
                            return (
                                <div className=" border rounded p-1 m-2 bg-light cursor"
                                    onClick={() => handleReadNotification(item._id)} key={item._id}
                                >
                                    <div className="d-flex">
                                        <Icon children={"person-circle"} size={"5"} className='mx-2' />
                                        <div>
                                            <i>{item.title}</i>
                                            <div style={{ fontSize: "13px  " }} className='text-secondary'>{item.description}</div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <i style={{ fontSize: "10px" }} className='text-secondary fw-bold'>{formattedDateTime.slice(0, 21)}</i>
                                    </div>
                                </div>
                            )
                        }
                        )
                    )}
                </div>
            </div>
        </>
    )
}

export default NotificationBell