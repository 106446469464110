import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  trainerapi,
  createtrainerapi,
  updatetrainerapi,
  deletetrainerapi,
} from "../../../Api_url";
import { toast } from "react-toastify";
export const token = localStorage.getItem("token");
export const _id = localStorage.getItem("_id");

const headers = {
  Authorization: `Bearer ${token}`,
};
// create trainer
export const createtrainer = createAsyncThunk(
  "createtrainer/trainer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(createtrainerapi, data, { headers });
      return response.data.trainer;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// get trainer
export const gettrainer = createAsyncThunk("gettrainer/trainer", async () => {
  try {
    const response = await axios.get(`${trainerapi}/${_id}?searchQuary=`, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
});
// update
export const updatetrainers = createAsyncThunk(
  "updatetrainers/trainer",
  async ({ selectedId, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${updatetrainerapi}/${selectedId}`,
        formData,
        { headers }
      );
      return { id: selectedId, message: response.data.message }; // Adjust based on your API response
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// delete trainer
export const deletetrainers = createAsyncThunk(
  "deletetrainers/trainer",
  async (trainerid, { rejectWithValue }) => {
    try {
      await axios.delete(`${deletetrainerapi}/${trainerid}`, { headers });
      return trainerid; // Return the deleted plan ID
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const trainerSlice = createSlice({
  name: "trainer",
  initialState: {
    trainers: [],
    status: "idle",
    initialFetch: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create trainer
      .addCase(createtrainer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createtrainer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers.push(action.payload);
        state.message = "Trainer created successfully!";
        toast.success(state.message);
        state.status = "idle";
      })
      .addCase(createtrainer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          toast.error(action.payload);
        }
      })
      //    update
      .addCase(updatetrainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatetrainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        const updatedTrainer = state.trainers.find(
          (trainer) => trainer._id === action.meta.arg.selectedId
        );
        if (updatedTrainer) {
          const fields = [
            "name",
            "email",
            "phone",
            "age",
            "address",
            "salary",
            "experience",
            "image",
          ];
          fields.forEach((field) => {
            const value = action.meta.arg.formData.get(field);
            if (field === "image" && value) {
              updatedTrainer[field] = URL.createObjectURL(value);
            } else if (value) {
              updatedTrainer[field] = value;
            }
          });
        }
        toast.success(action.payload.message);
        state.status = "idle";
      })
      .addCase(updatetrainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      //gettrainers
      .addCase(gettrainer.pending, (state) => {
        state.initialFetch = "loading";
      })
      .addCase(gettrainer.fulfilled, (state, action) => {
        state.initialFetch = "succeeded";
        state.trainers = action.payload;
      })
      .addCase(gettrainer.rejected, (state, action) => {
        state.initialFetch = "failed";
        state.error = action.payload;
      })

      //delete trainers
      .addCase(deletetrainers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletetrainers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainers = state.trainers.filter(
          (trainers) => trainers._id !== action.payload
        );
        toast.success("Tainer Delete Successfully!!");
        state.status = "idle";
      })
      .addCase(deletetrainers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        toast.error(action.payload);
      });
  },
});

export default trainerSlice.reducer;
