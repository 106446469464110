import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { paymenthistory } from '../../ReduxToolkit/Slice/Plan/Plan';
import PageTitle from '../../components/Title/PageTitle';
import Table from '../../components/Table/Table';
import Icon from '../../components/Button/Icon';

function PaymentHistory() {
    const dispatch = useDispatch();
    const { status, payment, error } = useSelector((state) => state.plan);

    useEffect(() => {
        if (status === "idle") {
            dispatch(paymenthistory());
        }
    }, [dispatch, status]);

    const preprocessData = (data) => {
        return data.map(item => ({
            ...item,
            name: item.userData ? item.userData.name : '',
            userphone: item.userData ? item.userData.phone : '',
            planname: item.planData ? item.planData.name : '',
            planprize: item.planData ? item.planData.price : '',
            planduration: item.planData ? item.planData.duration : '',
        }));
    };

    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    function formatExpiredDate(dateString) {
        return (
            <span className="text-danger">
                {formatDate(dateString)}
            </span>
        );
    }

    const columns = [
        { label: "Member Name", accessor: "name" },
        { label: "Phone", accessor: "userphone" },
        { label: "Plan", accessor: "planname" },
        { label: "Duration", accessor: "planduration", formatter: (duration) => (
            <span>
                {duration} Month
            </span>
        ) },
        {
            label: "Price",
            accessor: "planprize",
            formatter: (price) => (
                <span className="text-success">
                    <Icon children={"plus"} textColor='success' /><Icon children={"currency-rupee"} textColor='success' />{price}
                </span>
            )
        },
        { label: "Date", accessor: "planAssignedOn", formatter: formatDate },
        { label: "Expired on", accessor: "planExpiredOn", formatter: formatExpiredDate },
    ];

    return (
        <div>
            <div className='d-flex justify-content-between mb-3'>
                <PageTitle>Payment History ({payment.length ? payment.length : ""})</PageTitle>
            </div>
            <Table
                columns={columns}
                data={preprocessData([...payment].reverse() || [])}
                status={status}
                error={error}
            />
        </div>
    );
}

export default PaymentHistory;
