import React, { useState, useEffect } from 'react';
import { BgColor, TextColor } from '../../components/Color/Color';
import { useDispatch, useSelector } from "react-redux";
import { getorders, deleteorders, updateorder } from '../../ReduxToolkit/Slice/Store/Order';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Icon from '../../components/Button/Icon';

function Order() {
    const dispatch = useDispatch();
    const { status, orders, error } = useSelector((state) => state.order);
    const [limit] = useState(20);
    const [pagination, setPagination] = useState(1);
    const [search, setSearch] = useState("");
    //update order
    const [orderStatus, setOrderStatus] = useState(false)
    const updatestatus = (id) => {
        const newStatus = !orderStatus;
        setOrderStatus(newStatus);
        const data = {
            status: newStatus,
        };
        dispatch(updateorder({ id, data }))
        console.log(data)
    }
    // const updatestatus = async (id) => {
    //     const orderToToggle = orders.find(order => order._id === id);
    //     if (!orderToToggle) return;
    
    //     // Ensure that the status is a boolean before toggling
    //     const currentStatus = orderToToggle.status;
    //     const updatedStatus = !currentStatus;
    //     const data = { status: updatedStatus };
    
    //     console.log(`Order ID: ${id}`);
    //     console.log(`Current Status: ${currentStatus}`);
    //     console.log(`Updated Status: ${updatedStatus}`);
    //     console.log(`Data to be dispatched:`, data);
    
    //     await dispatch(updateorder({ id, data }));
    // };
    
    // get
    useEffect(() => {
        if (status === "idle") {
            dispatch(getorders({ limit, pagination, search }));
        }
    }, [status,limit, pagination, search,dispatch])
    // filter
    useEffect(() => {
        dispatch(getorders({ limit, pagination, search }));
    }, [dispatch, limit, pagination, search,]);
    //pagination
    const handlePaginationChange = (newPage) => {
        setPagination(newPage);
        dispatch(getorders({ limit, search, pagination: newPage }));
    };
    // delete
    const handleDelete = (orderId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this order?");
        if (isConfirmed) {
            dispatch(deleteorders(orderId));
        }
    }
    return (
        <>
            <div>
                <div className="row justify-content-between mb-3">
                    <div className='col-lg-6 col-12'>
                        <h4>Orders ({orders.length})</h4>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="row justify-content-end">
                            <div className="col-lg-4 col-6 text-end">
                                
                                <Input type="text" placeholder='Search here'  onChange={(e) => setSearch(e.target.value)}/>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <table className="table table-hover">
                        <thead className={`table-${BgColor}`}>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Product</th>
                                <th scope="col">Date</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status === "loading" && (
                                <tr className='my-2'>
                                    <td colSpan={7}>
                                        <Skeleton count={10} height={60} />
                                    </td>
                                </tr>
                            )}
                            {status === "failed" || null  && (
                                <tr>
                                    <td colSpan={7}>
                                        <p className='text-center fw-bold'>{error}</p>
                                    </td>
                                </tr>
                            )}
                            {status === "succeeded" && orders.map((order, index) => {
                                const dateTime = new Date(order.createdAt);
                                const year = dateTime.getFullYear();
                                const month = ("0" + (dateTime.getMonth() + 1)).slice(-2); // Months are 0-based
                                const day = ("0" + dateTime.getDate()).slice(-2);
                                const hours = ("0" + dateTime.getHours()).slice(-2);
                                const minutes = ("0" + dateTime.getMinutes()).slice(-2);
                                //    const seconds = ("0" + dateTime.getSeconds()).slice(-2);
                                // Get the day of the week
                                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                                const dayOfWeekIndex = dateTime.getDay();
                                const dayOfWeek = daysOfWeek[dayOfWeekIndex];
                                const formattedDateTime = `${day}-${month}-${year} (${hours}:${minutes}) ${dayOfWeek}`;
                                return (
                                    <tr key={order._id}>
                                        <th scope="row">
                                            <div className='position-relative'>
                                                <span className={`position-absolute bottom-0 start-0  rounded-circle text-${TextColor} bg-${BgColor} text-center`} style={{ fontSize: "10px", height: "17px", width: "17px" }}>{index + 1}</span>
                                                <Link to={`/orderdetails/${order._id}`}>   {
                                                    order.orderby.image ?
                                                        <img
                                                            src={order.orderby.image}
                                                            height={"50px"}
                                                            width={"50px"}
                                                            className="rounded-circle img-fit"
                                                            alt="img"
                                                        /> : <img
                                                            src={"https://img.freepik.com/premium-vector/fitness-gym-logo_23987-169.jpg"}
                                                            height={"50px"}
                                                            width={"50px"}
                                                            className="rounded-circle img-fit"
                                                            alt="img"
                                                        />}
                                                </Link>
                                            </div>
                                        </th>
                                        <td>{order.orderby.name.charAt(0).toUpperCase() + order.orderby.name.slice(1)}</td>
                                        <td>{order.orderby.phone}</td>
                                        <td>{order.products.length}</td>
                                        <td>{formattedDateTime}</td>
                                        <td className='cursor' onClick={() => updatestatus(order._id)}>{order.orderStatus ? <i className='bg-success px-2 rounded-pill text-white'>Deliverd</i> : <i className='bg-warning px-2 rounded-pill'>Pending</i>}</td>
                                        <td className='cursor'>
                                            <span onClick={() => handleDelete(order._id)}><Icon children={"trash"} textColor='danger' size={"5"}/></span>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-between">
                    <div className="col-lg-4">
                        <p>{`Showing ${pagination} to ${Math.min(limit * pagination, orders.length)} of ${orders.length} Orders`}</p>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <div className="pagination">
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(Math.max(1, pagination - 1))}
                                disabled={pagination === 1}
                            >
                                Previous
                            </button>
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(pagination + 1)}
                                disabled={orders.length < limit}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Order;
