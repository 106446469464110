import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { BgColor } from "../Color/Color";
import Icon from "../Button/Icon";

const Table = ({ columns, data, status, onDelete, onEdit, databstarget, model, error, Image }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead className={`table-${BgColor}`}>
          <tr>
            <th scope="col">S.no</th>
            {Image && (<th scope="col">Image</th>)}
            {columns.map((column, index) => (
              <th key={index} scope="col">
                {column.label}
              </th>
            ))}
            {(onDelete || onEdit) && (
                <th scope="col">Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {status === "loading" && (
            <tr className="my-2">
              <td colSpan={columns.length + 3}>
                <Skeleton count={10} height={70} />
              </td>
            </tr>
          )}
          {status === "failed" && (
            <tr>
              <td colSpan={columns.length + 3}>
                <p className="text-center fw-bold text-danger">{error}</p>
              </td>
            </tr>
          )}
          {status === "succeeded" &&
            data.map((item, index) => (
              <tr key={item._id}>
                <td>
                  <td>{index + 1}</td>
                </td>
                {Image && (
                  <td> {
                    (item.img || item.categoryImg || item.image) ?
                      <img
                        src={item.categoryImg || item.img || item.image}
                        height={item.img ? "60px" : "50px"}
                        width={item.img ? "160px" : "50px"}
                        className={item.img ? "rounded img-fit" : "rounded-circle img-fit"}
                        alt="img"
                      /> : <img
                        src={"https://img.freepik.com/premium-vector/fitness-gym-logo_23987-169.jpg"}
                        height={item.img ? "60px" : "5px"}
                        width={item.img ? "160px" : "50px"}
                        className={item.img ? "rounded img-fit" : "rounded-circle img-fit"}
                        alt="img"
                      />}</td>
                )}
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {column.accessor
                      ? column.accessor === "serialNumber"
                        ? index + 1
                        : column.formatter
                          ? column.formatter(item[column.accessor])
                          : item[column.accessor]
                      : ""}
                  </td>
                ))}
                <td>
                  {onEdit && (<div className="cursor" onClick={() => onEdit(item._id)} data-bs-toggle={model} data-bs-target={databstarget}>
                    <i className={`bi bi-pencil-square text-${BgColor} fs-5`}></i>
                  </div>)}
                  {onDelete && (<div className="cursor" onClick={() => onDelete(item._id)}>
                    <Icon children={"trash"} size={"5"} textColor="danger" />
                  </div>)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
