import React from 'react';

export default function Model({
    id = "exampleModal",
    className = "",
    title = "",
    children,
    ...props
}) {
    return (
        <>
            <div
                className={`modal fade ${className}`}
                id={id}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                {...props}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5`}>
                                {title}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
