import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BgColor, TextColor } from '../../components/Color/Color';
import { getcompetition, updatecompetitions, deletecompetitions, addprize, deleteprize, updatecompetitionsstatus, createWinner, getwinner,deletewinner } from '../../ReduxToolkit/Slice/Competition/Competition';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CreateCompetition from './CreateCompetition';
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Icon from '../../components/Button/Icon';

function Competition() {
    const [activeCard, setActiveCard] = useState(null);
    const closeButtonRef = useRef(null);
    const { status, competitions, winners, error } = useSelector((state) => state.competition);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [endDate, setEndDate] = useState("");
    const [competitionDate, setCompetitionDate] = useState("");

    // single data
    const [selectedId, setSelectedId] = useState(null);
    const handlid = (id) => {
        const selectedcompetitions = competitions.find(plan => plan._id === id);
        setName(selectedcompetitions.name);
        setDescription(selectedcompetitions.description);
        setEndDate(selectedcompetitions.endDate);
        setCompetitionDate(selectedcompetitions.competitionDate);
        setSelectedId(id);
    };
    // update 
    const updatecompetition = (e) => {
        e.preventDefault()
        const data = {
            name,
            description,
            endDate,
            competitionDate
        }
        dispatch(updatecompetitions({ selectedId, data }))
        closeButtonRef.current.click();
    }

    // filter
    const [limit] = useState(20)
    const [pagination, setpagination] = useState(1)
    // const [search, setsearch] = useState("")
    // const [Gender, setGender] = useState("")

    // get 
   
    useEffect(() => {
        if (status === "idle") {
            dispatch(getcompetition({ limit, pagination }));

        }
    }, [status, dispatch]);
    // get winner
    const [competetionID, SetcompetetionID] = useState()

    useEffect(() => {
        dispatch(getwinner(competetionID ? competetionID : "6627207c2877777bd3d799c0" ))
    }, [competetionID])


    const handlePaginationChange = (newPage) => {
        setpagination(newPage);
        dispatch(getcompetition({ limit, pagination: newPage }));
    };
    // addprice
    const [id, setId] = useState(null)
    const [position, setPosition] = useState("")
    const [prize, setPrize] = useState("")
    const handlePrize = (e) => {
        e.preventDefault()
        const data = {
            position: position,
            prize: prize
        }
        dispatch(addprize({ id, data }))
        setPrize("")
        setPosition("")
        closeButtonRef.current.click();
    }
    //  delet competiton
    const deletecompetion = (id) => {
        dispatch(deletecompetitions(id))
    }
    //  delet prize
    const deletecompetionPrize = (comId, prizeId) => {
        dispatch(deleteprize({ comId, prizeId }))
    }
    //  delet winnerId
    const deletewinnerhandle = (winnerId) => {
        dispatch(deletewinner({ winnerId }))
    }
    // update status of competition
    const togglecompetitionStatus = async (id) => {
        const competitionToToggle = competitions.find(competition => competition._id === id);
        if (!competitionToToggle) return;
        const updatedStatus = !competitionToToggle.status;
        const data = { status: updatedStatus };
        await dispatch(updatecompetitionsstatus({ id, data }));
    }

    // create winner
    const [winnerId, setwinnerId] = useState(null)
    const [winnerName, setwinnerName] = useState("")
    const [winningposition, setwinningposition] = useState("")
    const [prizeName, setprizeName] = useState("")
    const createwinnerhandle = (e) => {
        e.preventDefault()
        const data = {
            winnerName: winnerName,
            competetionData: winnerId,
            position: winningposition,
            prizeName: prizeName
        }

        console.log(data)
        dispatch(createWinner(data))
        setwinnerId("")
        setwinnerName("")
        setwinningposition("")
        setprizeName("")
        closeButtonRef.current.click();
    }



    return (
        <>
            <div>
                <div className='row d-flex justify-content-between mb-3'>
                    <div className="col-lg-6">
                        <h4>Gym competitions({competitions.length ? competitions.length : ""})</h4>
                    </div>
                    <div className="col-lg-6 text-end">
                        <CreateCompetition />
                    </div>

                </div>

                {/*competition*/}
                <div className="row">
                    {status === "loading" && (
                        <>
                            {
                                Array.from({ length: 10 }).map((index) => (
                                    <div className='col-lg-4 my-2' key={index}>
                                        <Skeleton count={1} height={300} />
                                    </div>
                                ))
                            }
                        </>
                    )}
                    {status === "failed" && (
                        <div>
                            <div colSpan={8}>
                                <p className='text-center fw-bold'>{error}</p>
                            </div>
                        </div>
                    )}
                    {status === "succeeded" && competitions && competitions.map((competition, index) => {
                        const data = new Date(competition.competitionDate);
                        const competitionDate = data.toLocaleDateString("default", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric"
                        });
                        return (
                            <>
                                <div className="col-lg-6" key={index}>
                                    <div className='p-3 shadow-sm bg-white rounded mb-3'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <h4>{competition.name.charAt(0).toUpperCase() + competition.name.slice(1)}</h4>{" "}
                                                <i className='cursor'
                                                    onClick={() => togglecompetitionStatus(competition._id)}
                                                >{competition.status ? <i className='bg-success px-2 rounded-pill text-white'> Completed</i> : <i className='bg-warning px-2 rounded-pill'>UpComing</i>}</i>
                                            </div>
                                            <Icon children={"three-dots-vertical"} className="cursor" size={"5"} onClick={() => setActiveCard(activeCard === index ? null : index)} />


                                        </div>
                                        <div className={`position-relative ${activeCard === index ? '' : 'd-none'}`}>
                                            <div className='w-50 shadow rounded position-absolute top-0 end-0 bg-white' style={{ zIndex: "1" }}>
                                                <div className='p-2'>
                                                    <div className='border-bottom py-1 cursor text-success' onClick={() => setId(competition._id)} data-bs-toggle="modal" data-bs-target="#exampleModalPrize">
                                                        <Icon children={"plus-circle"} textColor='success' size={"6"} />
                                                        {" "}Add Prize</div>
                                                    <div className='border-bottom py-1 cursor text-success' onClick={() => setwinnerId(competition._id)} data-bs-toggle="modal" data-bs-target="#exampleModalwinner">
                                                        <Icon children={"plus-circle"} textColor='success' size={"6"} />
                                                        {" "}Create Winner</div>
                                                    <div className={`border-bottom py-1 cursor text-${BgColor}`} onClick={() => handlid(competition._id)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                        <Icon children={"pencil-square"} size={"6"} />
                                                        {" "}Edit</div>
                                                    <div className='py-1 text-danger cursor' onClick={() => deletecompetion(competition._id)}>  <Icon children={"trash"} textColor='danger' size={"6"} />{" "}delete</div>
                                                </div>
                                            </div>
                                        </div>



                                        <i className={`bg-${"secondary"} text-${TextColor} py-1 px-3 rounded-pill`}>{competitionDate}</i>
                                        <div>
                                            {competition.description}
                                        </div>
                                        {competition.prizes.map((prize, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className='d-flex justify-content-between bg-light my-1 p-2 rounded'>
                                                        <div>
                                                            <div>{prize.position}</div>
                                                            <div style={{ fontSize: "13px" }} className='text-secondary'><i className='bi bi-trophy'></i> {prize.prizeName}</div>
                                                        </div>
                                                        <Icon children={"trash"} textColor='danger' className='cursor' size={"6"} onClick={() => deletecompetionPrize(competition._id, prize._id)} />
                                                    </div>

                                                </div>
                                            );
                                        })}
                                        <div className='my-2'>
                                            <div class="accordion accordion-flush" id="accordionFlushParticipants">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header px-0">
                                                        <button class="accordion-button collapsed px-1 py-1" type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#flush-collapseParticipants${index}`}
                                                            aria-expanded="false"
                                                            aria-controls={`flush-collapseParticipants${index}`}>
                                                            Participants
                                                        </button>
                                                    </h2>
                                                    <div id={`flush-collapseParticipants${index}`} class="accordion-collapse collapse"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionFlushParticipants">
                                                        <div class="accordion-body">
                                                            {competition.participants.map((participant, pIndex) => (
                                                                <div key={pIndex}>
                                                                    <div>{" "}{participant.name}</div>
                                                                    <div style={{ fontSize: "13px" }} className='text-secondary'>{participant.phone}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* winners */}
                                        <div className='my-2'>
                                            <div class="accordion accordion-flush" id="accordionFlushWinners">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header px-0">
                                                        <button class="accordion-button collapsed px-1 py-1" type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#flush-collapseWinners${index}`}
                                                            aria-expanded="false"
                                                            aria-controls={`flush-collapseWinners${index}`}
                                                            onClick={() => SetcompetetionID(competition._id)}
                                                        >
                                                            Winners
                                                        </button>
                                                    </h2>
                                                    <div id={`flush-collapseWinners${index}`} class="accordion-collapse collapse"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionFlushWinners">
                                                        <div class="accordion-body">
                                                            {winners && winners.map((winner, wIndex) => (
                                                                <>
                                                                    <div key={wIndex} className='d-flex justify-content-between'>
                                                                       <div >
                                                                       <div>{" "}{winner.competetionData.name} <span className='text-success'>{winner.position}</span></div>
                                                                        <div>{" "}<span>Prize :</span> {winner.prizeName}</div>

                                                                        <div>{winner.winnerName.name}{" "}({winner.winnerName.phone})</div>
                                                                       
                                                                       </div>
                                                                       <Icon children={"trash"} size={"6"} textColor='danger' onClick={()=>deletewinnerhandle(winner._id)} />
                                                                    </div>
                                                                    <hr />
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
                {/* end */}
                <div className="row justify-content-between my-2">
                    <div className="col-lg-4">
                        <p>{`Showing ${pagination} to ${competitions.length} of ${competitions.length} Projects`}</p>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-end">
                        <div className="pagination">
                            {/* Previous button */}
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(Math.max(1, pagination - 1))}
                                disabled={pagination === 1}
                            >
                                Previous
                            </button>
                            {/* Next button */}
                            <button
                                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                                onClick={() => handlePaginationChange(pagination + 1)}
                                disabled={competitions.length < limit}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal  */}
            <Model id='exampleModal' title='Create Competition'>
                <form onSubmit={updatecompetition}>
                    <div className="row text-start">
                        <Input label="Competition Name" type="text" bodyclass="col-lg-12" required value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name" />
                        <Input label="End Date" type="date" bodyclass="col-lg-12" required value={endDate}
                            onChange={(e) => setEndDate(e.target.value)} />
                        <Input label="Duration" type="text" bodyclass="col-lg-12" required value={competitionDate}
                            onChange={(e) => setCompetitionDate(e.target.value)} />
                        <div className="mb-3 col-lg-12">
                            <label htmlFor="descriptionInput" className="form-label">Description</label>
                            <textarea
                                required
                                className="form-control"
                                id="descriptionInput"
                                name="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter the description"
                            />
                        </div>
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Update"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
            {/* add price */}
            <div
                className="modal fade"
                id="exampleModalPrize"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5`} id="exampleModalLabel">
                                Add Prize
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <form action="" onSubmit={handlePrize}>
                                <div className="row">
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="positionInput" className="form-label">Position</label>
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            id="positionInput"
                                            name="position"
                                            value={position}
                                            onChange={(e) => setPosition(e.target.value)}
                                            placeholder="Enter position"
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="prizeInput" className="form-label">Prize</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="prizeInput"
                                            name="prize"
                                            placeholder="Enter Prize"
                                            value={prize}
                                            onChange={(e) => setPrize(e.target.value)}
                                        />
                                    </div>

                                    <div className={`modal-footer`}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            ref={closeButtonRef}
                                        >
                                            Close
                                        </button>
                                        <button type="submit" className={`btn btn-${BgColor} text-${TextColor}`}>
                                            Upload
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* add exampleModalwinner */}
            <div
                className="modal fade"
                id="exampleModalwinner"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5`} id="exampleModalLabel">
                                Create Winner
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <form action="" onSubmit={createwinnerhandle}>
                                <div className="row">

                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="winnerNameInput" className="form-label">Winner Name</label>
                                        <select
                                            className="form-select"
                                            id="winnerSelect"
                                            name="position"
                                            value={winnerName}
                                            onChange={(e) => setwinnerName(e.target.value)}
                                        >
                                            <option value="">Select Winner</option>
                                            {competitions.map((competition) => (
                                                competition.participants.map((participant) => (
                                                    <option value={participant._id} key={participant._id}>{participant.name}</option>
                                                ))
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="winningpositionInput" className="form-label">Position</label>
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            id="winningpositionInput"
                                            name="winningposition"
                                            value={winningposition}
                                            onChange={(e) => setwinningposition(e.target.value)}
                                            placeholder="Enter position"
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <label htmlFor="prizeNameInput" className="form-label">Prize</label>
                                        <input
                                            required
                                            type="text"
                                            className="form-control"
                                            id="prizeNameInput"
                                            name="prizeName"
                                            placeholder="Enter Prize"
                                            value={prizeName}
                                            onChange={(e) => setprizeName(e.target.value)}
                                        />
                                    </div>

                                    <div className={`modal-footer`}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            ref={closeButtonRef}
                                        >
                                            Close
                                        </button>
                                        <button type="submit" className={`btn btn-${BgColor} text-${TextColor}`}>
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Competition;
