import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglemember, deletemembers, memberupdateplan } from '../../ReduxToolkit/Slice/Member/Member';
import { useNavigate, useParams } from 'react-router-dom';
import { BgColor, TextColor } from '../../components/Color/Color';
import { getplan } from '../../ReduxToolkit/Slice/Plan/Plan';
import { dummyImage } from '../../components/Img/Img';

function MemberProfile() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, Singlemember, error } = useSelector((state) => state.member);
    const { plans } = useSelector((state) => state.plan);
    const [plan, setPlan] = useState('');
    const [palnStatus, setpalnStatus] = useState(true);
    const planupdateat = new Date(Singlemember.planUpdatedOn);
    const updateDate = planupdateat.toLocaleDateString("default", {
        day: "2-digit",
        month: "long",
        year: "numeric"
    });
    const expirey = new Date(Singlemember.ExpiryDate);
    const expireyDates = expirey.toLocaleDateString("default", {
        day: "2-digit",
        month: "long",
        year: "numeric"
    });
    useEffect(() => {
        dispatch(getSinglemember(id));
        dispatch(getplan())
    }, [dispatch, id]);

    if (status === 'loading') {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <Skeleton height={500} width={'100'} />
                    </div>
                </div>
            </>
        );
    }

    if (status === 'failed') {
        return <div className="text-center">{error}</div>;
    }

    if (!Singlemember) {
        return null; // or show a loading indicator
    }
    // delete api
    const handleDelete = () => {
        const Isconfirmed = window.confirm("Are you sure! you want delete this Member ?")
        if (Isconfirmed) {
            dispatch(deletemembers(id))
            navigate("/members")
        };
    }
    // update plan

    const updatepaln = () => {
        const data = {
            plan: plan,
            status: palnStatus
        }
        dispatch(memberupdateplan({id,data}))
        navigate("/members")


    }

    return <div>
        <>
            <div className="row justify-content-center">
                <div className="col-lg-10 my-2">
                    <div className='shadow-sm bg-white rounded p-3'>
                        <div className='text-center'>
                            {Singlemember.image ? <img src={Singlemember.image} i alt="" height={100} width={100} className='rounded-circle img-fit' /> : <img src={dummyImage} i alt="" height={100} width={100} className='rounded-circle img-fit' />}
                            <h4 className='text-center'>Profile</h4>
                            <button type="submit" className={`btn btn-sm btn-${BgColor} text-${TextColor}`}  data-bs-toggle="modal" data-bs-target="#exampleModal">Update Plan</button>
                        </div>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">Name</th>
                                    <td>{Singlemember.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Plan</th>
                                    <td>{Singlemember.plan ? Singlemember.plan.name :<i className='px-2 rounded-pill bg-danger text-white'>Expired</i>}
                                    <span className={Singlemember.plan ? "" :"d-none"}>/₹</span>{Singlemember.plan ? Singlemember.plan.price : ""}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Plan Date</th>
                                    <td>{Singlemember.plan ? updateDate : ""}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Plan Expired</th>
                                    <td>{Singlemember.plan ? expireyDates : ""}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Gender</th>
                                    <td>{Singlemember.timeSlot}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Gender</th>
                                    <td>{Singlemember.gender}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Phone</th>
                                    <td>{Singlemember.phone}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Email</th>
                                    <td>{Singlemember.email}</td>
                                </tr>
                               


                                <tr>
                                    <th scope="row">Address</th>
                                    <td>{Singlemember.address},{Singlemember.state},{Singlemember.city},{Singlemember.pincode}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                {/* <button type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal" className={`btn btn-${BgColor} text-${TextColor} my-3 mx-1`}>Send Notification</button> */}
                <button type="submit" className={`btn btn-${"danger"} text-${TextColor} my-3 mx-1`} onClick={handleDelete}>Delete Account</button>
            </div>
            {/* Modal  */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5`} id="exampleModalLabel">
                                Update Plan
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-3 col-lg-6">
                                    <label htmlFor="genderInput" className="form-label">Status</label>
                                    <select name="gender" className='form-control' id="genderInput" value={palnStatus} required
                                        onChange={(e) => setpalnStatus(prevStatus => !prevStatus)}>
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                    </select>
                                </div>
                                <div className={`mb-3 col-lg-6`}>
                                    <label htmlFor="PlanInput" className="form-label">Select Plan</label>
                                    <select name="Plan" className='form-control' id="PlanInput" value={plan} required
                                        onChange={(e) => setPlan(e.target.value)}>
                                        <option value="" >Select  Plan</option>
                                        {plans.map((data) => (
                                            <option value={data._id} key={data._id}>{data.name}/ ₹{data.price}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className={`modal-footer`}>
                                    <button type="submit" data-bs-dismiss="modal"
                                        onClick={updatepaln}
                                        className={`btn btn-${BgColor} text-${TextColor}`}>
                                        Update Plan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </div>;
}

export default MemberProfile;
