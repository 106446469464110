import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getsupportApi,
  deletesupportApi,
  updatesupportApi,
  getfeedbackApi,
  deletefeedbackApi,
} from "../../..//Api_url";
import { toast } from "react-toastify";
export const token = localStorage.getItem("token");
export const _id = localStorage.getItem("_id");

const headers = {
  Authorization: `Bearer ${token}`,
};

// get support
export const getsupport = createAsyncThunk(
  "getsupport/support",
  async ({ limit, pagination }) => {
    try {
      const response = await axios.get(
        `${getsupportApi}?page=${pagination}&limit=${limit}&searchQuary`,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
// get feedback
export const getfeedback = createAsyncThunk("getfeedback/support", async () => {
  try {
    const response = await axios.get(
      `${getfeedbackApi}?page&limit&searchQuary`,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
});
// update
export const updatesupports = createAsyncThunk(
  "updatesupports/support",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${updatesupportApi}/${id}`, data, {
        headers,
      });
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// delete support
export const deletesupport = createAsyncThunk(
  "deletesupports/support",
  async (supportid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${deletesupportApi}/${supportid}`, {
        headers,
      });
      return response.data._id;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
// delete feedback
export const deletefeedback = createAsyncThunk(
  "deletefeedback/support",
  async (feedbacktid, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${deletefeedbackApi}/${feedbacktid}`,
        { headers }
      );
      return response.data._id;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const supportSlice = createSlice({
  name: "support",
  initialState: {
    supports: [],
    feedback: [],
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //update support
      .addCase(updatesupports.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatesupports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        state.status = "idle";
      })
      .addCase(updatesupports.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          alert(state.error);
        }
      })

      //getsupports
      .addCase(getsupport.pending, (state) => {
        state.status = "loading";
      })
      // .addCase(getsupport.fulfilled, (state, action) => {
      //     state.status = 'succeeded';
      //     if (action.meta.arg.pagination === 1) {
      //         state.supports = action.payload;
      //     } else {
      //         state.supports = [...state.supports, ...action.payload];
      //     }
      // })
      .addCase(getsupport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.supports = action.payload;
      })
      .addCase(getsupport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //getfeedback
      .addCase(getfeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getfeedback.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feedback = action.payload;
      })
      .addCase(getfeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      //delete supports
      .addCase(deletesupport.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletesupport.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.supports = state.supports.filter(
          (supports) => supports.supportid !== action.payload
        );
        if (state.status === "succeeded") {
          toast.success("Support Delete Succefully!!");
        }
        state.status = "idle";
      })
      .addCase(deletesupport.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      //delete
      .addCase(deletefeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletefeedback.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feedback = state.feedback.filter(
          (feedback) => feedback.feedbacktid !== action.payload
        );
        if (state.status === "succeeded") {
          toast.success("Support Delete Succefully!!");
        }
        state.status = "idle";
      })
      .addCase(deletefeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default supportSlice.reducer;
