import React from "react";
import { BgColor, TextColor } from '../../components/Color/Color';

function Button({
  children,
  type = "button",
  bgColor = BgColor,
  textColor = TextColor,
  className = "",
  icon = "",
  ...props
}) {
  return (
    <button
      className={`btn btn-${bgColor} text-${textColor} ${className}`}
      {...props}
    >
      <i className={`bi bi-${icon}`}></i> {children}
    </button>
  );
}

export default Button;
