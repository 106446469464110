import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { profileapi, updateprofileapi, updateprofileImageapi, totalcountApi } from "../../../Api_url";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};

// get totalcount
export const totalcount = createAsyncThunk('totalcount/profile', async () => {
    try {
        const response = await axios.get(`${totalcountApi}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// get Profile
export const getprofile = createAsyncThunk('getprofile/profile', async () => {
    try {
        const response = await axios.get(`${profileapi}/${_id}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// Profile update
export const updateprofiles = createAsyncThunk('updateprofiles/profile', async (formData, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updateprofileapi}/${_id}`, formData, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// update profile Image
export const updateprofileImage = createAsyncThunk('updateprofileImage/profile', async (formData, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updateprofileImageapi}/${_id}`, formData, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// delete Profile
export const deleteprofiles = createAsyncThunk('deleteprofiles/profile', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${profileapi}/${id}`, { headers });
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profiles: [],
        totalcounts: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //totalcount
            .addCase(totalcount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(totalcount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.totalcounts = action.payload;
            })
            .addCase(totalcount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //update profile
            .addCase(updateprofiles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateprofiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = "idle"
            })
            .addCase(updateprofiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update updateprofileImage
            .addCase(updateprofileImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateprofileImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                state.status = "idle"
            })
            .addCase(updateprofileImage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //getprofiles
            .addCase(getprofile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getprofile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profiles = action.payload;
            })
            .addCase(getprofile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //delete profiles
            .addCase(deleteprofiles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteprofiles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status = 'succeeded') {
                    toast.success('Profile Delete Successfully!!')
                }
            })
            .addCase(deleteprofiles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status = 'failed') {
                    toast.success(state.error)
                }
            });
    }
});

export default profileSlice.reducer;
