import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getfeedback, deletefeedback } from '../../ReduxToolkit/Slice/Support/Support';
import Table from '../../components/Table/Table';

function Feedback() {
    const dispatch = useDispatch();
    const { status, feedback,error} = useSelector((state) => state.support);
    useEffect(() => {
        // if (status === "idle") {
        //     dispatch(getfeedback());
        // }
        dispatch(getfeedback());
    }, [dispatch]);
    console.log(feedback)
    // delete api
    const handleDelete = (feedbacktid) => {
        const Isconfirmed = window.confirm("Are you sure! you want delete this support ?")
        if (Isconfirmed) {
            dispatch(deletefeedback(feedbacktid))
        };
    }
    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    const columns = [
        { label: "Suggestion", accessor: "suggestion" },
        { label: "User Name", accessor: "name" },
        { label: "Date", accessor: "createdAt", formatter: formatDate },
    ];
    const preprocessData = (data) => {
        return data.map(item => ({
            ...item,
            name: item.userData ? item.userData.name : '',
        }));
    };
    return (
        <>
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <h4>Gym Feedbacks({feedback ? feedback.length : ""})</h4>
                </div>
                <Table
                    columns={columns}
                    data={preprocessData(feedback|| [])}
                    status={status}
                    error={error}
                    onDelete={handleDelete}
                />
            </div>
        </>
    );
}

export default Feedback;
