import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getattendance } from '../../ReduxToolkit/Slice/Member/Member';
import { useParams } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';

function Attendance() {



    const [value, onChange] = useState(new Date());
    const { id } = useParams();
    const dispatch = useDispatch();
    const { status, Getattendance, error } = useSelector((state) => state.member);

    useEffect(() => {
        dispatch(getattendance(id));
    }, [dispatch, id]);

    const tileContent = ({ date }) => {
        if (Getattendance && status === 'succeeded') {
            const formattedDate = format(date, 'dd/MM/yyyy');
            const attendanceOnDate = Getattendance.filter(entry => {
                return entry.attendance.some(a => {
                    const entryDate = format(new Date(a.date), 'dd/MM/yyyy');
                    return entryDate === formattedDate && a.isPresent;
                });
            });
            if (attendanceOnDate.length > 0) {
                return <div className={`text-center bg-success`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '15px', height: '15px' }}></div>;
            }
        }
        return null;
    };



    if (status === 'loading') {
        return (
            <div className="row justify-content-center">
                <div className="col-lg-7">
                    <Skeleton height={300} width={'100'} />
                </div>
            </div>
        );
    }

    if (status === 'failed') {
        return <div className="text-center text-bold text-danger">{error || "Your plan has been expired!. update your suscription for access services"}</div>;
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-lg-7 my-2">
                    <div className='shadow-sm bg-white rounded p-3'>
                        <Calendar onChange={onChange} value={value} tileContent={tileContent} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Attendance;
