import React, { useState, useRef } from 'react';
import { createplan } from '../../ReduxToolkit/Slice/Plan/Plan';
import { useDispatch } from "react-redux";
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function CreatePlan() {
  const dispatch = useDispatch();
  const closeButtonRef = useRef(null);
  const [name, setName] = useState("");
  const [price, setprice] = useState("");
  const [duration, setduration] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch(createplan({ name, price, duration }));
    setName("");
    setprice("");
    setduration("");
    closeButtonRef.current.click();
  };
  return (
    <>
      <Button children={"Create"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#CreatePlan" />
      <Model id='CreatePlan' title='Create Plan'>
        <form onSubmit={handleSubmit}>
          <div className="row text-start">
            <Input label="Plan Name" type="text" bodyclass="col-lg-12" value={name} onChange={(e) => setName(e.target.value)}
              placeholder="Enter Plan Name" required />
            <Input label="Price" type="text" bodyclass="col-lg-12" required  value={price}
              onChange={(e) => setprice(e.target.value)}
              placeholder="Enter price" />
            <Input label="Duration" type="text" bodyclass="col-lg-12" required value={duration}
              onChange={(e) => setduration(e.target.value)}
              placeholder="Enter duration" />
            <div className={`modal-footer`}>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              >
                Close
              </button>
              <Button type='submit' children={"Create"}></Button>
            </div>
          </div>
        </form>
      </Model>
    </>
  )
}

export default CreatePlan