import React, { useState, useEffect } from "react";
import { BgColor, TextColor } from "../../components/Color/Color";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyOtp } from "../../ReduxToolkit/Slice/Login/VerifySlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function VerifyOtpPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.verify);
  const [email] = useState(location.state?.email || "");
  const [otp, setOtp] = useState("");

  const otpChange = (event) => {
    setOtp(event.target.value);
  };

  const formData = (e) => {
    e.preventDefault();
    dispatch(verifyOtp({ email:email, otp }));
  };

  useEffect(() => {
    if (status === "failed") {
      toast.error(error);
    }
    if (status === "succeeded") {
      navigate("/forget-Password", { state: { email } });
    }
  }, [status, error, navigate, email]);

  return (
    <>
      <div className="row justify-content-center my-5">
        <div className="col-lg-4 col-11 bg-light rounded py-3">
          <form onSubmit={formData}>
            <div className="text-center">
              <img
                src={require("../../img/logo.png")}
                className="rounded-circle"
                height={80}
                width={80}
                alt="logo"
              />
            </div>
            <div className="form-floating my-3">
              <input
                type="number"
                className="form-control"
                id="floatingInput"
                placeholder="Enter Otp"
                value={otp}
                onChange={otpChange}
              />
              <label htmlFor="floatingInput">Verify Otp</label>
            </div>
            <button
              className={`btn btn-${BgColor} text-${TextColor} w-100 py-2`}
              type="submit"
            >
              {status === "loading" ? (
                <div
                  className="spinner-border spinner-border-sm text-white"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "VERIFY OTP"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default VerifyOtpPage;
