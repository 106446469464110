import React, { useState } from 'react';
import { BgColor, TextColor } from '../../components/Color/Color';
import { useNavigate } from "react-router-dom";
import { login } from '../../ReduxToolkit/Slice/Login/LoginSlice';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';


function Login() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.auth);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [lat, setLatitude] = useState(null);
    const [long, setLongitude] = useState(null);

    const emailChange = (event) => {
        setEmail(event.target.value);
    };

    const passwordChange = (event) => {
        setPassword(event.target.value);
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    console.log(position.coords.latitude)
                    console.log(position.coords.longitude)
                },
                (error) => {
                    toast.error("Error getting location: " + error.message + "please on location");
                }
            );
        } else {
            toast.error("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        getLocation();
    })
    const formData = (e) => {
        e.preventDefault();
        dispatch(login({ email, password, lat, long }));
    };

    useEffect(() => {
        if (status === "failed") {
            toast.error(error);
        }
        if (status === "succeeded") {
            history("/");
            window.location.reload(false);
        }
    }, [status]);

    //   show password
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>


            <div className="row justify-content-center flex-row-reverse" style={{ height: "95vh" }}>
                <div className="col-lg-12"></div>
                <div className="col-lg-7 content-center">
                    <div className=''>
                        <div className='text-center'>
                            <img src={require("../../img/logo.png")} className='rounded-circle' height={80} width={80} alt="" />
                        </div>
                        <p className='pragraph'>Digital HealthFit</p>
                        <h1 className='display-3'><b>Digital HealthFit</b>: Your Ultimate Automated Gym Management Solution</h1>
                        <p className='fs-5'>"Empowering Gyms, Enriching Journeys: Join the <span><a href="https://web.digitalhealthfit.com/" target='_blank'>DigitalHealthFit</a></span> Community Today!"</p>

                    </div>
                </div>
                <div className="col-lg-5 rounded py-3">
                    <form onSubmit={formData}>
                        <div>
                            <h1 className="h1 mb-3 fw-normal text-center fw-bold">Sign In</h1>
                            <div className="form-floating my-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={email}
                                    onChange={emailChange}
                                />
                                <label htmlFor="floatingInput">Email address</label>
                            </div>
                            <div className="form-floating mt-4">
                                <input
                                    className="form-control"
                                    id="floatingPassword"
                                    placeholder="Password"
                                    value={password}
                                    onChange={passwordChange}
                                    type={showPassword ? "text" : "password"}

                                />
                                <label htmlFor="floatingPassword">Password</label>
                            </div>
                            <div className='mb-4 mt-2 d-flex justify-content-between'>
                                <div className="form-check">
                                    <input type="checkbox" onClick={togglePasswordVisibility} className="form-check-input"  id="exampleCheck1"/>
                                    <label className="form-check-label" for="exampleCheck1">  {showPassword ? "Hide" : "Show"} </label>
                                </div>
                                <div className="fw-bold">
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        <Link to="/send-otp" className='text-dark'>Forget Password</Link>
                                    </label>
                                </div>
                            </div>                    
                            <button className={`btn btn-${BgColor} text-${TextColor} w-100 py-3`} type="submit">
                                {status === "loading" ? (
                                    <div className="spinner-border spinner-border-sm text-white" style={{ color: "#1976D2" }} role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    "Login"
                                )}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
}

export default Login;
