import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { forgetPasswordapi } from "../../../Api_url";
import { toast } from "react-toastify";

// forget password
export const forgetPassword = createAsyncThunk(
  "forgetPassword/password",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(forgetPasswordapi, data);
      return res.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const passwordSlice = createSlice({
  name: "password",
  initialState: {
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create forget
      .addCase(forgetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          toast.success(state.message);
        }
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default passwordSlice.reducer;
