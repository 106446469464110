import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { createcompetition } from '../../ReduxToolkit/Slice/Competition/Competition';
import Button from '../../components/Button/Button';
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';

function CreateCompetition() {
    const closeButtonRef = useRef(null); 
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [endDate, setEndDate] = useState("");
    const [competitionDate, setCompetitionDate] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name,
            description,
            endDate,
            competitionDate
        }
        dispatch(createcompetition(data));
        setName("");
        setDescription("");
        setEndDate("");
        setCompetitionDate("");
        closeButtonRef.current.click();
    };
    return (
        <>
            <Button children={"Create"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#CreateCompetiton" />
            <Model id='CreateCompetiton' title='Create Competition'>
                <form onSubmit={handleSubmit}>
                    <div className="row text-start">
                        <Input label="Competition Name" type="text" bodyclass="col-lg-12" required value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name" />
                        <Input label="End Date" type="date" bodyclass="col-lg-12" required value={endDate}
                            onChange={(e) => setEndDate(e.target.value)} />
                        <Input label="Duration" type="text" bodyclass="col-lg-12" required   value={competitionDate}
                                            onChange={(e) => setCompetitionDate(e.target.value)}/>
                                            <div className="mb-3 col-lg-12">
                                        <label htmlFor="descriptionInput" className="form-label">Description</label>
                                        <textarea
                                            required
                                            className="form-control"
                                            id="descriptionInput"
                                            name="description"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Enter the description"
                                        />
                                    </div>
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Create"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default CreateCompetition