import React, { useState, useRef, useEffect } from 'react';
import { createcategory } from '../../ReduxToolkit/Slice/Store/Category';
import { useDispatch } from "react-redux";
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function CreateCategory() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const [categoryName, setCategoryName] = useState("");
    const [categoryImg, setCategoryImg] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('categoryName', categoryName)
        data.append('categoryImg', categoryImg)
        dispatch(createcategory(data));
        setCategoryName("");
        setCategoryImg(null);
        closeButtonRef.current.click();

    };
    return (
        <>
            <Button children={"Create"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#CreateCategory" />
            <Model id='CreateCategory' title='Create Category'>
                <form onSubmit={handleSubmit}>
                    <div className="row text-start">
                        <Input label="Name" type="text" bodyclass="col-lg-12" value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            placeholder="Enter category Name" required />

                        <Input label="Image" type="file" bodyclass="col-lg-12" required name="image"
                            onChange={(e) => setCategoryImg(e.target.files[0])} />
                       
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Create"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default CreateCategory