import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getbanner, deletebanners, updatebanners } from '../../ReduxToolkit/Slice/Banner/Banner';
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import UploadBanner from './UploadBanner';
import PageTitle from '../../components/Title/PageTitle';
import Table from '../../components/Table/Table';

function Banner() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const [name, setName] = useState("");
    const [img, setImg] = useState(null);
    const { status, banners,error } = useSelector((state) => state.banner);

    useEffect(() => {
        if (status === "idle") {
            dispatch(getbanner());
        }
    }, [dispatch, status]);
    // single data
    const [selectedId, setSelectedId] = useState(null);
    const handlid = (id) => {
        const selectedbanners = banners.find(banner => banner._id === id);
        setName(selectedbanners.name);
        setImg(selectedbanners.img);
        setSelectedId(id);
    };
    // update 
    const updatebanner = (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('name', name)
        data.append('img', img)
        dispatch(updatebanners({ selectedId, data }))
        setName("");
        setImg(null);
        closeButtonRef.current.click();
    }
    // delete api
    const handleDelete = (bannerid) => {
        const Isconfirmed = window.confirm("Are you sure! you want delete this banner ?")
        if (Isconfirmed) {
            dispatch(deletebanners(bannerid))
        };
    }
    // column
    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }
    const columns = [
        { label: "Banner Name", accessor: "name" },
        { label: "Date", accessor: "createdAt", formatter: formatDate },
    ];

    return (
        <>
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <PageTitle children={`Gym Banners (${banners.length ? banners.length : ""})`} />
                    <UploadBanner />
                </div>
                 <Table
                    columns={columns}
                    data={banners|| []}
                    status={status}
                    error={error}
                    Image={"image"}
                    onDelete={handleDelete}
                    onEdit={handlid}
                    databstarget="#exampleModal"
                    model="modal"
                />
            </div>

            {/* Modal  */}
            <Model id='exampleModal' title='Update Banner'>
                <form onSubmit={updatebanner}>
                    <div className="row text-start">
                        <Input label="Name" type="text" bodyclass="col-lg-12" value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter banner Name" required />

                        <Input label="Image" type="file" bodyclass="col-lg-12" name="Image"
                            onChange={(e) => setImg(e.target.files[0])} />

                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"update"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    );
}

export default Banner;
