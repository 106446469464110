import React, { useState, useEffect } from "react";
import { BgColor, TextColor } from "../../components/Color/Color";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { forgetPassword } from "../../ReduxToolkit/Slice/Login/password";
import { toast } from "react-toastify";

function ForgetPasswordPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.password);
  const [email, setEmail] = useState(location.state?.email || "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const emailChange = (event) => {
    setEmail(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formData = (e) => {
    e.preventDefault();
    dispatch(forgetPassword({ email: email, password }));
  };

  useEffect(() => {
    if (status === "failed") {
      toast.error(error);
    }
    if (status === "succeeded") {
      navigate("/login");
    }
  }, [status, error, navigate]);

  return (
    <div className="row justify-content-center my-5">
      <div className="col-lg-4 col-11 bg-light rounded py-3">
        <form onSubmit={formData}>
          <div className="text-center">
            <img
              src={require("../../img/logo.png")}
              className="rounded-circle"
              height={80}
              width={80}
              alt="logo"
            />
          </div>
          <h1 className="h3 mb-3 fw-normal">Change Your Password</h1>
          <div className="form-floating my-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="enter your email"
              value={email}
              onChange={emailChange}
            />
            <label htmlFor="floatingInput">Your Email</label>
          </div>
          <div className="form-floating my-3">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="floatingPassword"
              placeholder="New Password"
              value={password}
              onChange={passwordChange}
              required
            />
            <label htmlFor="floatingPassword">New Password</label>
          </div>
          <div className="mb-4 mt-2 d-flex justify-content-between">
            <div className="form-check">
              <input
                type="checkbox"
                onClick={togglePasswordVisibility}
                className="form-check-input"
                id="exampleCheck1"
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                {showPassword ? "Hide" : "Show"}
              </label>
            </div>
          </div>
          <button
            className={`btn btn-${BgColor} text-${TextColor} w-100 py-2`}
            type="submit"
          >
            {status === "loading" ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                style={{ color: "#1976D2" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Forget Password"
            )}
          </button>
          {status === "failed" && <p>{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default ForgetPasswordPage;
