import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  loginapi,
} from "../../../Api_url";

// api calls
export const login = createAsyncThunk(
  "login/auth",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(loginapi, data);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("_id", res.data._id);
      return res.data.token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);



const authSlice = createSlice({
  name: "auth",
  initialState: {
    status: "idle",
    error: null,
    message: null,
    token: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // login
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.token = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.message = null;
      })
  },
});

export default authSlice.reducer;
