import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { planapi, createplanapi, updateplanapi, deleteplanapi,paymenthistoryApi } from "../../../Api_url";
import { toast } from 'react-toastify';

export const token = localStorage.getItem("token");

const headers = {
    Authorization: `Bearer ${token}`,
};

// create plan
export const createplan = createAsyncThunk('createplan/plan', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createplanapi, data, { headers });
        return response.data.plan; // Assuming the API returns the created plan object
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// get plan
export const getplan = createAsyncThunk('getplan/plan', async () => {
    try {
        const response = await axios.get(planapi, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// payment history
export const paymenthistory = createAsyncThunk('paymenthistory/plan', async () => {
    try {
        const response = await axios.get(paymenthistoryApi, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});

// update
export const updateplans = createAsyncThunk('updateplans/plan', async ({ selectedId, data }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updateplanapi}/${selectedId}`, data, { headers });
        return { id: selectedId, data: response.data.plan }; // Assuming the API returns the updated plan object
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});;

// delete plan
export const deleteplans = createAsyncThunk('deleteplans/plan', async (planid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deleteplanapi}/${planid}`, { headers });
        console.log(response)
        return planid; // Return the deleted plan ID
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

const planSlice = createSlice({
    name: 'plan',
    initialState: {
        plans: [],
        payment: [],
        status: 'idle',
        initialFetch: 'idle',  // Separate state for initial data fetch
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // create plan
            .addCase(createplan.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createplan.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.plans.push(action.payload);
                state.message = "Plan created successfully!";
                toast.success(state.message);
                state.status = 'idle';
            })
            .addCase(createplan.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                toast.error(state.error);
            })

            // update plan
            // .addCase(updateplans.pending, (state) => {
            //     state.initialFetch = 'loading';
            // })
            // .addCase(updateplans.fulfilled, (state, action) => {
            //     state.initialFetch = 'succeeded';
            //     const updatedPlanIndex = state.plans.findIndex(plan => plan._id === action.payload.id);
            //     if (updatedPlanIndex !== -1) {
            //         state.plans[updatedPlanIndex] = { ...state.plans[updatedPlanIndex], ...action.payload.data };
            //     }
            //     toast.success("Plan updated successfully!");
            // })
            // .addCase(updateplans.rejected, (state, action) => {
            //     state.initialFetch = 'failed';
            //     state.error = action.payload;
            //     toast.error(state.error);
            // })

            .addCase(updateplans.pending, (state) => {
                state.initialFetch = 'loading';
            })
            .addCase(updateplans.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload; 
                state.initialFetch = 'idle'
                toast.success(action.payload);
            })
            .addCase(updateplans.rejected, (state, action) => {
                state.initialFetch = 'failed';
                state.error = action.payload;
                toast.error(action.payload);
            })

            // get plans
            .addCase(getplan.pending, (state) => {
                state.initialFetch = 'loading';
            })
            .addCase(getplan.fulfilled, (state, action) => {
                state.initialFetch = 'succeeded';
                state.plans = action.payload;
            })
            .addCase(getplan.rejected, (state, action) => {
                state.initialFetch = 'failed';
                state.error = action.payload;
            })
            // get payment history
            .addCase(paymenthistory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(paymenthistory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.payment = action.payload;
            })
            .addCase(paymenthistory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // delete plans
            .addCase(deleteplans.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteplans.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.plans = state.plans.filter(plan => plan._id !== action.payload);
                toast.success("Plan deleted successfully!");
                state.status = 'idle';
            })
            .addCase(deleteplans.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                toast.error(action.payload);
            });
    }
});

export default planSlice.reducer;
