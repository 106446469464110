import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { bannerapi, createbannerapi, updatebannerapi, deletebannerapi } from "../../../Api_url";
import { toast } from "react-toastify";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};
// create banner
export const createbanner = createAsyncThunk('createbanner/banner', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createbannerapi, data, { headers })
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get banner
export const getbanner = createAsyncThunk('getbanner/banner', async () => {
    try {
        const response = await axios.get(`${bannerapi}/${_id}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// update
export const updatebanners = createAsyncThunk('updatebanners/banner', async ({ selectedId, data }, { rejectWithValue }) => {
    try {
        const response = await axios.put(`${updatebannerapi}/${selectedId}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// delete banner
export const deletebanners = createAsyncThunk('deletebanners/banner', async (bannerid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deletebannerapi}/${bannerid}`, { headers });
        return response.data._id;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});



const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
        banners: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create banner
            .addCase(createbanner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createbanner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(createbanner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })
            //update banner
            .addCase(updatebanners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatebanners.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(state.message)
                }
                state.status = 'idle';

            })
            .addCase(updatebanners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(state.error)
                }
            })

            //getbanners
            .addCase(getbanner.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getbanner.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.banners = action.payload;
            })
            .addCase(getbanner.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete banners
            .addCase(deletebanners.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletebanners.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.banners = state.banners.filter(banners => banners.bannerid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Banner Delete Succefully!!")
                }
                state.status = 'idle'
            })
            .addCase(deletebanners.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default bannerSlice.reducer;
