import React, { createContext, useContext, useState } from "react";

const FlowContext = createContext();

export const FlowProvider = ({ children }) => {
  const [hasAccess, setHasAccess] = useState(false);

  return (
    <FlowContext.Provider value={{ hasAccess, setHasAccess }}>
      {children}
    </FlowContext.Provider>
  );
};

export const useFlow = () => useContext(FlowContext);
