import React, { useState, useRef } from 'react';
import { createbanner } from '../../ReduxToolkit/Slice/Banner/Banner';
import { useDispatch } from "react-redux";
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

function UploadBanner() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const [name, setName] = useState("");
    const [img, setImg] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = new FormData()
        data.append('name', name)
        data.append('img', img)
        dispatch(createbanner(data));
        setName("");
        setImg(null);
        closeButtonRef.current.click();

    };
    return (
        <>
            <Button children={"Create"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#UploadBanner" />
            <Model id='UploadBanner' title='Upload Banner'>
                <form onSubmit={handleSubmit}>
                    <div className="row text-start">
                        <Input label="Name" type="text" bodyclass="col-lg-12" value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter banner Name" required />

                        <Input label="Image" type="file" bodyclass="col-lg-12" required name="Image"
                            onChange={(e) => setImg(e.target.files[0])} />

                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Upload"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default UploadBanner