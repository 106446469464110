import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Siderbar from '../Sidebar/Siderbar'
import { useDispatch, useSelector } from "react-redux";
import { getprofile } from '../../ReduxToolkit/Slice/Profile/Profile';
import NotificationBell from './NotificationBell';
function Navbar() {
  
  const dispatch = useDispatch();
  const { profiles } = useSelector((state) => state.profile);
  useEffect(() => {
    dispatch(getprofile());
  }, [dispatch]);
  return (
    <>
      <div className='d-flex justify-content-between p-2'>

        <span
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-decoration-none"
        >
          <>
            <span className=''>
              <span><i className='bi bi-list fs-2 me-2 d-lg-none' data-bs-toggle="offcanvas" href="#offcanvasExample"></i></span>
              <span className="fs-5 fw-bold">{profiles.gym ? profiles.gym.gymName : "Loading.."}</span>
            </span>
          </>
        </span>

        <div className='d-flex'>
         <NotificationBell />
         <NavLink className="dropdown-item" to="/profile">
            {
                profiles.gym ? <img
                  src={profiles.gym.image}
                  alt="img..."
                  width={32}
                  height={32}
                  className="rounded-circle me-2 img-fit"
                /> : ""
              }

            <strong className='d-none d-md-inline'>{profiles.gym ? profiles.gym.ownerName : "loading"}</strong>
                </NavLink>
        </div>
      </div>
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{ width: "70vw" }}>
        <div className="offcanvas-body">
          {/* <i className="bi bi-x btn-close"  aria-label="Close"></i> */}
          <div data-bs-dismiss="offcanvas">
            <Siderbar />
          </div>
        </div>
      </div>
    </>

  )
}

export default Navbar