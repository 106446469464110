import React, { useState } from 'react'
import { createnotificationforall } from '../../ReduxToolkit/Slice/Notification/Notification';
import { useDispatch } from "react-redux";
import { BgColor, TextColor } from '../../components/Color/Color';


function MemberNotification() {
    const dispatch = useDispatch();
    const [title, setTitle] = useState()
    const [description, setdescription] = useState()

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(createnotificationforall({ title, description }))
    }
    return (
        <>
            <i className='bi bi-bell cursor' data-bs-toggle="modal" data-bs-target="#exampleModal1"></i>
            {/* Modal  */}
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className={`modal-title fs-5 text-${BgColor}`} id="exampleModalLabel">
                                Send Notification
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <form action="" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="mb-3 col-lg-12">
                                        <input
                                            required
                                            type="title"
                                            className="form-control"
                                            id="titleInput"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            placeholder="Enter Title"
                                        />
                                    </div>
                                    <div className="mb-3 col-lg-12">
                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            id="descriptionInput"
                                            name="description"
                                            value={description}
                                            onChange={(e) => setdescription(e.target.value)}
                                            placeholder="Enter Description"
                                        />
                                    </div>
                                    <div className={`modal-footer`}>
                                        <button type="submit" data-bs-dismiss="modal" className={`btn btn-${BgColor} text-${TextColor}`}>
                                          <i className='bi bi-bell'></i>  Send Notification
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MemberNotification