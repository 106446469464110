import React from 'react'

function PageTitle({
    children,
    className
}) {
  return (
    <h4 className={`${className}`}>{children}</h4>
  )
}

export default PageTitle