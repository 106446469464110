import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { orderapi, allordersapi, updateorderapi } from "../../../Api_url";
import { toast } from "react-toastify";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};
// get orders
export const getorders = createAsyncThunk('getorders/orders', async ({ limit, pagination, search }) => {
    try {
        const response = await axios.get(`${allordersapi}?limit${limit}=&page${pagination}=&searchQuary=${search}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// get orders deatils
export const getordersDetails = createAsyncThunk('getordersDetails/orders', async (id) => {
    try {
        const response = await axios.get(`${orderapi}/${id}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});

// delete orders
export const deleteorders = createAsyncThunk('deleteorderss/orders', async (ordersid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${orderapi}/${ordersid}`, { headers });
        return response.data._id;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

// update
export const updateorder = createAsyncThunk('updateorder/support', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`${updateorderapi}/${id}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});

const ordersSlice = createSlice({
    name: 'order',
    initialState: {
        orders: [],
        ordersDetails: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //getorderss
            .addCase(getorders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getorders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = action.payload;
            })
            .addCase(getorders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //getorders deatils
            .addCase(getordersDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getordersDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ordersDetails = action.payload;
            })
            .addCase(getordersDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete orderss
            .addCase(deleteorders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteorders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.orders = state.orders.filter(orderss => orderss.ordersid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Order Delete Succcessfully!!")
                }
                state.status = 'idle'
            })
            .addCase(deleteorders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            //update support
            .addCase(updateorder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateorder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                state.status = 'idle';

            })
            .addCase(updateorder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                   toast.error(state.error)
                }
            })
    }
});

export default ordersSlice.reducer;
