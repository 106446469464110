import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getnotificationunread, deleteNotificationunread } from '../../ReduxToolkit/Slice/Notification/Notification';
import Table from '../../components/Table/Table';


function Notification() {
    const dispatch = useDispatch();
    const { status, notifications,error} = useSelector((state) => state.notification);
    useEffect(() => {
        if (status === "idle") {
            dispatch(getnotificationunread());
        }
    }, [dispatch, status]);
    // delete api
    const handleDelete = (notificationid) => {
        const Isconfirmed = window.confirm("Are you sure! you want delete this notification ?")
        if (Isconfirmed) {
            dispatch(deleteNotificationunread(notificationid))
        };
    }
    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const preprocessData = (data) => {
        return data.map(item => ({
            ...item,
            description: item.description || 'Send by User',
        }));
    };
    const columns = [
        { label: "Notification Title", accessor: "title" },
        { label: "Description", accessor: "description" },
        { label: "Date", accessor: "createdAt", formatter: formatDate },
    ];
    
    return (
        <>
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <h4>Gym Notifications({notifications.notificationData ? notifications.notificationData.length : ""})</h4>
                </div>
                <Table
                    columns={columns}
                    data={preprocessData(notifications.notificationData || [])}
                    status={status}
                    error={error}
                    onDelete={handleDelete}
                />
            </div>
        </>
    );
}

export default Notification;
