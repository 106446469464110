import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getplan, deleteplans, updateplans } from '../../ReduxToolkit/Slice/Plan/Plan';
import PageTitle from '../../components/Title/PageTitle';
import CreatePlan from './CeatePlan';
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Table from '../../components/Table/Table';

function Plan() {
    const dispatch = useDispatch();
    const closeButtonRef = useRef(null);
    const {initialFetch, plans, error} = useSelector((state) => state.plan);
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [duration, setDuration] = useState("");
    useEffect(() => {
        if (initialFetch === "idle") {
            dispatch(getplan());
        }
    }, [dispatch, initialFetch]);

    // Single data
    const [selectedId, setSelectedId] = useState(null);
    const handleId = (id) => {
        const selectedPlan = plans.find(plan => plan._id === id);
        setName(selectedPlan.name);
        setPrice(selectedPlan.price);
        setDuration(selectedPlan.duration);
        setSelectedId(id);
    };

    // Update plan
    // const updatePlan = (e) => {
    //     e.preventDefault();
    //     const data = { name, price, duration };
    //     dispatch(updateplans({ selectedId, data }));
    //     setName("");
    //     setPrice("");
    //     setDuration("");
    //     closeButtonRef.current.click();
    // };
    // Function to handle plan update
    const updatePlan = (e) => {
        e.preventDefault();
        if (selectedId) {
            const data = { name, price, duration };
            dispatch(updateplans({ selectedId, data }))
                .then(() => {
                    closeButtonRef.current.click();
                    setName("");
                    setPrice("");
                    setDuration("");
                    setSelectedId(null);
                })
                .catch((err) => {
                    console.error('Failed to update the plan:', err);
                });
        }
    };

    // Delete API
    const handleDelete = (planId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this plan?");
        if (isConfirmed) {
            dispatch(deleteplans(planId));
        }
    };
//   table

const columns = [
    { label: "Plan Name", accessor: "name" },
    { label: "Price", accessor: "price" },
    { label: "Duration", accessor: "duration" },
];
    return (
        <>
            <div>
                <div className='d-flex justify-content-between mb-3'>
                    <PageTitle children={`Gym Plans (${plans.length ? plans.length : ""})`} />
                    <CreatePlan />
                </div>
               
                <Table
                    columns={columns}
                    data={plans}
                    status={initialFetch}
                    onDelete={handleDelete}
                    onEdit={handleId}
                    error={error}
                    databstarget="#exampleModal"
                    model="modal"
                />
            </div>

            {/* Modal  */}
            <Model id='exampleModal' title='Update Plan'>
                <form onSubmit={updatePlan}>
                    <div className="row text-start">
                        <Input label="Plan Name" type="text" bodyclass="col-lg-6" value={name} onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Plan Name" required />
                        <Input label="Price" type="text" bodyclass="col-lg-6" value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            placeholder="Enter price" />
                        <Input label="Duration" type="text" bodyclass="col-lg-6" value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            placeholder="Enter duration" />
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Update"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    );
}

export default Plan;
