import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  productapi,
  createproductsapi,
  allproductsapi,
} from "../../../Api_url";
import { toast } from "react-toastify";
export const token = localStorage.getItem("token");
export const _id = localStorage.getItem("_id");

const headers = {
  Authorization: `Bearer ${token}`,
};
// create products
export const createproducts = createAsyncThunk(
  "createproducts/products",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(createproductsapi, data, { headers });
      return response.data.product;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// get products
export const getproducts = createAsyncThunk(
  "getproducts/products",
  async ({ limit, pagination, search }) => {
    try {
      const response = await axios.get(
        `${allproductsapi}/${_id}?limit${limit}=&page${pagination}=&searchQuary=${search}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
// update
export const updateproductss = createAsyncThunk(
  "updateproductss/products",
  async ({ selectedId, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${productapi}/${selectedId}`, data, {
        headers,
      });
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// delete products
export const deleteproductss = createAsyncThunk(
  "deleteproductss/products",
  async (productsid, { rejectWithValue }) => {
    try {
      await axios.delete(`${productapi}/${productsid}`, { headers });
      return productsid;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    productss: [],
    status: "idle",
    initialFetch: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create products
      .addCase(createproducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createproducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productss.push(action.payload);
        state.message = "Product created successfully!";
        toast.success(state.message);
        state.status = "idle";
      })
      .addCase(createproducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          toast.error(state.error);
        }
      })
      //update products
      .addCase(updateproductss.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateproductss.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          toast.success(state.message);
        }
        state.status = "idle";
      })
      .addCase(updateproductss.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        if (state.status === "failed") {
          toast.error(state.error);
        }
      })

      //getproductss
      .addCase(getproducts.pending, (state) => {
        state.initialFetch = "loading";
      })
      .addCase(getproducts.fulfilled, (state, action) => {
        state.initialFetch = "succeeded";
        state.productss = action.payload;
      })
      .addCase(getproducts.rejected, (state, action) => {
        state.initialFetch = "failed";
        state.error = action.payload;
      })

      //delete productss
      .addCase(deleteproductss.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteproductss.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productss = state.productss.filter(
          (productss) => productss._id !== action.payload
        );
        toast.success("Product Delete Successfully!!");
        state.status = "idle";
      })
      .addCase(deleteproductss.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default productsSlice.reducer;
