import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { createnotificationforallapi,getNotificationunreadApi,deleteNotificationunreadApi,verifynotificationApi } from "../../../Api_url";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};
// create notification
export const createnotificationforall = createAsyncThunk('createnotification/notification', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(createnotificationforallapi, data, { headers })
        return response.data.message;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
})

// get notification
export const getnotificationunread = createAsyncThunk('getnotification/notification', async () => {
    try {
        const response = await axios.get(`${getNotificationunreadApi}/${_id}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});

// delete notification
export const deleteNotificationunread = createAsyncThunk('deletenotifications/notification', async (notificationid, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${deleteNotificationunreadApi}/${notificationid}`, { headers });
        return response.data._id;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});
export const VerifyNotification = createAsyncThunk('Notification/VerifyNotification', async ({ id, data  }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`${verifynotificationApi}/${id}`, data);
        const message = response.data.message;
        return message;

    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});


const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notifications: [],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //create notification
            .addCase(createnotificationforall.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createnotificationforall.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload;
                if (state.status === 'succeeded') {
                    toast.success(action.payload)
                }
                state.status = 'idle';

            })
            .addCase(createnotificationforall.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)
                }
            })
            //getnotifications
            .addCase(getnotificationunread.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getnotificationunread.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notifications = action.payload;
            })
            .addCase(getnotificationunread.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            //delete notifications
            .addCase(deleteNotificationunread.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteNotificationunread.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.notifications.notificationData = state.notifications.notificationData.filter(notifications => notifications.notificationid !== action.payload);
                if (state.status === 'succeeded') {
                    toast.success("Notification Delete Succefully!!")
                }
                state.status = 'idle'
            })
            .addCase(deleteNotificationunread.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
             // verify
             .addCase(VerifyNotification.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(VerifyNotification.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.message = action.payload; 
                state.status = 'idle'
            })
            .addCase(VerifyNotification.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to update category';
            })
    }
});

export default notificationSlice.reducer;
