import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from 'react-toastify';
import { getallcolorthemeApi, selectthemeApi,selectedthemeApi } from "../../../Api_url";
export const token = localStorage.getItem("token")
export const _id = localStorage.getItem("_id")

const headers = {
    Authorization: `Bearer ${token}`,
};
// get color theme
export const getallcolortheme = createAsyncThunk('getallcolortheme/colortheme', async () => {
    try {
        const response = await axios.get(`${getallcolorthemeApi}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// selected color
export const selectedtheme = createAsyncThunk('selectedtheme/colortheme', async () => {
    try {
        const response = await axios.get(`${selectedthemeApi}`, { headers });
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
});
// create theme
export const selecttheme = createAsyncThunk('selecttheme/colortheme', async (data, {dispatch, getState,rejectWithValue }) => {
    try {
        const response = await axios.post(`${selectthemeApi}`, data, { headers });
        return response.data.message
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});




const colorthemeSlice = createSlice({
    name: 'colorTheme',
    initialState: {
        allthemes: [],
        selectedthemecolor:[],
        status: 'idle',
        error: null,
        message: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            //getallcolortheme
            .addCase(getallcolortheme.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getallcolortheme.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.allthemes = action.payload;
            })
            .addCase(getallcolortheme.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // selected theme color
            .addCase(selectedtheme.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selectedtheme.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedthemecolor = action.payload;
            })
            .addCase(selectedtheme.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })


            //selecttheme
            .addCase(selecttheme.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(selecttheme.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedthemecolor = [action.payload];
                if (state.status === 'succeeded') {
                    alert(`${action.payload} Please refresh the page`);
                }
               window.location.reload(false)
            })
            .addCase(selecttheme.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                if (state.status === 'failed') {
                    toast.error(action.payload)
                }
            })
    }
});

export default colorthemeSlice.reducer;
