import React, { useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { createtrainer} from '../../ReduxToolkit/Slice/Trainer/Trainer';
import Button from '../../components/Button/Button';
import Model from '../../components/Model/Model';
import Input from '../../components/Input/Input';

function AddTrainer() {
    const closeButtonRef = useRef(null);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [age, setAge] = useState("");
    const [experience, setExperience] = useState("");
    const [address, setAddress] = useState("");
    const [salary, setSalary] = useState("");
    const [image, setImage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('age', age);
        formData.append('address', address);
        formData.append('salary', salary);
        formData.append('experience', experience);
        formData.append('image', image);

        dispatch(createtrainer(formData));
        setName("");
        setEmail("");
        setPhone("");
        setAge("");
        setAddress("");
        setSalary("");
        setImage("");
        setExperience("")
        closeButtonRef.current.click();
    };
    return (
        <>
            <Button children={"Register"} icon='plus-circle-fill' data-bs-toggle="modal" data-bs-target="#AddTrainer" />
            <Model id='AddTrainer' title='Register Trainer'>
                <form onSubmit={handleSubmit}>
                    <div className="row text-start">
                        <Input
                            label="Name"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter Trainer Name"
                        />

                        <Input
                            label="Email"
                            type="email"
                            bodyclass="col-lg-12"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter Email"
                        />

                        <Input
                            label="Phone"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Enter Phone Number"
                        />

                        <Input
                            label="Age"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            placeholder="Enter Age"
                        />

                        <Input
                            label="Experience"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            placeholder="Enter Experience"
                        />
                        <Input
                            label="Address"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="Enter Address"
                        />
                        <Input
                            label="Salary"
                            type="text"
                            bodyclass="col-lg-12"
                            required
                            value={salary}
                            onChange={(e) => setSalary(e.target.value)}
                            placeholder="Enter Salary"
                        />
                        <Input
                            label="Image"
                            type="file"
                            bodyclass="col-lg-12"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        <div className={`modal-footer`}>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                Close
                            </button>
                            <Button type='submit' children={"Create"}></Button>
                        </div>
                    </div>
                </form>
            </Model>
        </>
    )
}

export default AddTrainer