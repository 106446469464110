import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getordersDetails } from '../../ReduxToolkit/Slice/Store/Order';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { BgColor, TextColor } from '../../components/Color/Color';
import { useParams } from 'react-router-dom';

function Orderdetails() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { status, ordersDetails, error } = useSelector((state) => state.order);
    useEffect(() => {
        // if (status === "idle") {
        dispatch(getordersDetails(id));
        // }
    }, [dispatch, id]);

    console.log(ordersDetails.products)


    return (
        <>
            <div className="row justify-content-center my-3">
                <div className='col-lg-11 d-flex justify-content-between'>
                    <h4>Orders  Details({ordersDetails.products ? ordersDetails.products.length : ""})</h4>
                    <h5 className='text-success'><b>₹{ordersDetails.totalPrice}</b>
                    </h5>
                </div>
                <div className="col-lg-11">
                    {status === "loading" && (
                        <Skeleton count={5} height={200} />
                    )}
                </div>
                <div className="col-lg-11">
                    {status === "failed" && (

                        <p className='text-center fw-bold text-center'>{error}</p>

                    )}
                </div>
                <div className='col-lg-11'>

                    {status === "succeeded" && ordersDetails.products && ordersDetails.products.map((order, index) => (
                        <>
                            <div className="row my-3 bg-white rounded p-3" key={order._id}>
                                <div className="col-lg-2">
                                    {order.product.image ?
                                        <img
                                            src={order.product.image}
                                            // height={"100px"}
                                            // width={"100px"}
                                            className="rounded img-fluid"
                                            alt="img"
                                        /> : <img
                                            src={"https://img.freepik.com/premium-vector/fitness-gym-logo_23987-169.jpg"}
                                            // height={"100px"}
                                            // width={"100px"}
                                            className="rounded img-fit"
                                            alt="img"
                                        />}
                                </div>
                                <div className="col-lg-10">
                                    <h5>{order.product.title}</h5>
                                    <p>{order.product.description}</p>
                                    <div className='d-flex justify-content-between'>
                                        <h6>Quantiy - {order.count}</h6>
                                        <h6>₹{order.product.price}</h6>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Orderdetails;
