import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { updateprofileImage } from '../../ReduxToolkit/Slice/Profile/Profile';
const BgColor =  localStorage.getItem("bgColor")
const TextColor = localStorage.getItem("TextColor")

const CustomFileUpload = ({ image: apiImage }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [localFile, setLocalFile] = useState(null);
    const [localImage, setLocalImage] = useState('');

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log('Selected file:', file.name);
            setLocalFile(file); 
            const imageUrl = URL.createObjectURL(file);
            setLocalImage(imageUrl);
        }
    };

    useEffect(() => {
        return () => {
            if (localImage) {
                URL.revokeObjectURL(localImage);
            }
        };
    }, [localImage]);

    const imageToShow = localImage || apiImage;

    const updateImage = () => {
        if (localFile) {
            const formData = new FormData();
            formData.append('image', localFile); 
            dispatch(updateprofileImage(formData));
        }
    };

    return (
        <div>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <div className='d-flex justify-content-center'>
                <div className='text-center position-relative'>
                    {imageToShow ? (
                        <img src={imageToShow} alt="" height={100} width={100} className='rounded-circle img-fit' />
                    ) : (
                        <img src="" alt="" height={100} width={100} className='rounded-circle img-fit' />
                    )}
                    <span className={`position-absolute bottom-0 end-0 cursor rounded-circle text-${BgColor} bg-${TextColor} text-center`} style={{ fontSize: "25px", height: "35px", width: "35px" }} onClick={handleIconClick} aria-label="Upload file">
                        <i className='bi bi-cloud-arrow-up'></i>
                    </span>
                </div>
            </div>
            {localImage && (
                <div className={`btn btn-sm text-${TextColor} bg-${BgColor} mt-2`} onClick={updateImage}>
                    Upload
                </div>
            )}
        </div>
    );
};

export default CustomFileUpload;
