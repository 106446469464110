import React, { useState, useEffect, useRef } from "react";
import { BgColor, TextColor } from "../../components/Color/Color";
import { useDispatch, useSelector } from "react-redux";
import {
  getproducts,
  deleteproductss,
  updateproductss,
} from "../../ReduxToolkit/Slice/Store/Products";
import { getcategory } from "../../ReduxToolkit/Slice/Store/Category";
import Model from "../../components/Model/Model";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import PageTitle from "../../components/Title/PageTitle";
import AddProduct from "./AddProduct";
import Table from "../../components/Table/Table";

function Products() {
  const dispatch = useDispatch();
  const closeButtonRef = useRef(null);
  const { initialFetch, productss, error } = useSelector(
    (state) => state.products
  );
  const { categorys } = useSelector((state) => state.category);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [categoryId, setCategoryId] = useState("");

  const [selectedId, setSelectedId] = useState(null);
  const handlid = (id) => {
    const selectedproducts = productss.find((products) => products._id === id);
    setTitle(selectedproducts.title);
    setPrice(selectedproducts.price);
    setDescription(selectedproducts.description);
    setImage(selectedproducts.image);
    setCategoryId(selectedproducts.categoryId);
    setSelectedId(id);
  };
  // update
  const updateproducts = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("title", title);
    data.append("description", description);
    data.append("price", price);
    data.append("image", image);
    data.append("categoryId", categoryId);
    dispatch(updateproductss({ selectedId, data }));
    setTitle("");
    setDescription("");
    setPrice("");
    setImage("");
    setCategoryId("");
    closeButtonRef.current.click();
  };

  // delete api
  const handleDelete = (productsid) => {
    const Isconfirmed = window.confirm(
      "Are you sure! you want delete this products ?"
    );
    if (Isconfirmed) {
      dispatch(deleteproductss(productsid));
    }
  };

  const [limit] = useState(20);
  const [pagination, setpagination] = useState(1);
  const [search, setsearch] = useState("");
  // get
  useEffect(() => {
    if (initialFetch === "idle") {
      dispatch(getproducts({ limit, pagination, search }));
      dispatch(getcategory());
    }
  }, [initialFetch, limit, pagination, search, dispatch]);
  // filter
  useEffect(() => {
    dispatch(getproducts({ limit, pagination, search }));
  }, [dispatch, limit, pagination, search]);
  const handlePaginationChange = (newPage) => {
    setpagination(newPage);
    dispatch(getproducts({ limit, search, pagination: newPage }));
  };
  // table

  const preprocessData = (data) => {
    return data.map((item) => ({
      ...item,
      categoryName: item.Category ? item.Category.categoryName : "",
    }));
  };

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const columns = [
    { label: "Products Name", accessor: "title" },
    { label: "Category", accessor: "categoryName" },
    { label: "Price", accessor: "price" },
    { label: "Description", accessor: "description" },
    { label: "Date", accessor: "createdAt", formatter: formatDate },
  ];

  return (
    <>
      <div>
        <div className="row justify-content-between mb-3">
          <div className="col-lg-6 col-12">
            <PageTitle
              children={`Gym Products (${
                productss.length ? productss.length : ""
              })`}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="row justify-content-end text-end">
              <div className="col-lg-6 col-md-8 col-6">
                <Input
                  type="text"
                  placeholder="Search here"
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-6">
                <AddProduct />
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={preprocessData(productss || [])}
          status={initialFetch}
          onDelete={handleDelete}
          onEdit={handlid}
          error={error}
          Image={"Image"}
          databstarget="#exampleModal"
          model="modal"
        />
        {/*  pagination */}
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <p>{`Showing ${pagination} to ${productss.length} of ${productss.length} Projects`}</p>
          </div>
          <div className="col-lg-4 d-flex justify-content-end">
            <div className="pagination">
              {/* Previous button */}
              <button
                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                onClick={() =>
                  handlePaginationChange(Math.max(1, pagination - 1))
                }
                disabled={pagination === 1}
              >
                Previous
              </button>
              {/* Next button */}
              <button
                className={`mx-1 btn bg-${BgColor} text-${TextColor}`}
                onClick={() => handlePaginationChange(pagination + 1)}
                disabled={productss.length < limit}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal  */}
      <Model id="exampleModal" title="Create Plan">
        <form onSubmit={updateproducts}>
          <div className="row text-start">
            <Input
              label="Title"
              type="text"
              bodyclass="col-lg-12"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter product's title"
              required
            />
            <div className="mb-3 col-lg-12">
              <label htmlFor="categorySelect" className="form-label">
                Category
              </label>
              <select
                className="form-select"
                id="categorySelect"
                name="categoryId"
                value={categoryId}
                required
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value="">Select a category</option>
                {categorys.map((category) => (
                  <option value={category._id} key={category._id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>
            </div>
            <Input
              label="Price"
              type="text"
              bodyclass="col-lg-12"
              required
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Enter price"
            />
            <Input
              label="Image"
              type="file"
              bodyclass="col-lg-12"
              name="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
            <div className="mb-3 col-lg-12">
              <label htmlFor="descriptionInput" className="form-label">
                Description
              </label>
              <textarea
                type="text"
                className="form-control"
                id="descriptionInput"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter description"
              />
            </div>
            <div className={`modal-footer`}>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              >
                Close
              </button>
              <Button type="submit" children={"Update"}></Button>
            </div>
          </div>
        </form>
      </Model>
    </>
  );
}

export default Products;
