import React, { useId } from "react";

const Input = React.forwardRef(function Input(
  { label, type = "text", name, placeholder, className = "", bodyclass="", ...props },
  ref
) {
  const id = useId();
  return (
    <div className={`mb-3 ${bodyclass}`}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      )}
      <input
        type={type}
        className={`form-control ${className}`}
        id={id}
        ref={ref}
        name={name}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
});

export default Input;
