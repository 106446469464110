import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { verifyOtpApi } from "../../../Api_url";
import { toast } from "react-toastify";
// send otp
export const verifyOtp = createAsyncThunk(
  "verifyOtp/verify",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(verifyOtpApi, data);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const verifySlice = createSlice({
  name: "verify",
  initialState: {
    status: "idle",
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create forget
      .addCase(verifyOtp.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload;
        if (state.status === "succeeded") {
          toast.success(state.message);
        }
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default verifySlice.reducer;
